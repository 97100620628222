import React from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import {
	extractForms,
	getFormHash,
	getFormStatuses,
	getSegmentsForReview,
	isFormDecisionRequired,
} from '@tf/shared';
import { Box, createStyles, Stack } from '@tf/ui';
import { S } from '@tf/utils';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import {
	AddConnectionButton,
	ConnectionMenu,
	EntityConnections,
	EntityKindIcon,
	FormStatusIndicator,
	ReviewGroupConnections,
	SideMenu,
	SideMenuItem,
} from '@/components/shared';
import { BulkReviewButton } from '@/components/shared/BulkReviewButton';

import { MenuLabel } from './MenuLabel';

interface Props {
	reviews: ExtendedReviewContainer[];
}

const useStyles = createStyles(() => ({
	root: {
		width: 280,
		marginRight: '1rem',
	},
	header: {
		overflow: 'hidden',
		borderRadius: '8px 8px 0 0',
	},
}));

export const Menu: React.FC<Props> = ({ reviews }) => {
	const { classes } = useStyles();
	const params = useAccountParams();
	const account = useSelectedAccount();
	const navigate = useNavigate();
	const isApplicant = params.connectionKind === 'APPLICANT';
	const currentReview = reviews.find((r) => r.connectionKind === params.connectionKind);

	const basePath = ['/accounts', params.accountId, S.slugify(params.connectionKind)].join('/');

	const formDefs = useSelector(() => appStore.defs.get().forms);
	const navigateToForm = (
		form: ExtendedFormDefinition<BackendTypes.FormDefinition>,
		connectionId: number
	) => {
		window.scrollTo(0, 0);
		const hash = getFormHash(form);
		return navigate(`${basePath}/${connectionId}/${form.name}?hash=${hash}`);
	};
	const navigateToEntity = (connectionId: number, entityId: number) => {
		window.scrollTo(0, 0);
		return navigate(`${basePath}/${connectionId}/entity/${entityId}`);
	};

	return (
		<Stack spacing={18} className={classes.root}>
			{reviews.map((r) => {
				const { connectionKind, graphLinkId } = r;
				const rootEntity = r.listEntities.find((e) => e.graphNodeId === r.graphNodeId)!;
				const segmentIdentities = getSegmentsForReview(account.meta, r).filter((c) => {
					return [account.meta.graphNodeId, r.graphNodeId, r.graphLinkId].includes(c.graphId);
				});
				const entityForms = extractForms({ formDefs, segmentIdentities });

				// * Segments on connections
				const accountEntity = r.listEntities.find((e) => {
					return e.graphNodeId === params.accountId;
				});
				const accountEntityConnection = accountEntity?.listConnections.find((e) => {
					return r.connectionKind === e.connectionKind;
				});
				const rootFromEntity = accountEntityConnection?.listFromEntities.find((e) => {
					return e.graphNodeId === r.graphNodeId && e.graphLinkId === r.graphLinkId;
				});
				const connectionSegments = rootFromEntity?.listSegments.map((s) => s.SegmentIdentity) || [];
				const connectionForms = extractForms({ formDefs, segmentIdentities: connectionSegments });

				return (
					<Box key={`${connectionKind}.${graphLinkId}`}>
						{isApplicant && (
							<BulkReviewButton graphId={rootEntity.graphNodeId} review={r} forms={entityForms} />
						)}

						<SideMenu>
							<Box className={classes.header}>
								<MenuLabel review={r} />
							</Box>

							{isApplicant && (
								<>
									{entityForms.map((f) => {
										const key = `${r.graphLinkId}.${f.name}` as const;
										const status = getFormStatuses({
											containers: r.listSegmentReviewContainers,
											segmentIdentities: f.segmentIdentities,
										});
										const selected =
											params.formKind === f.name && params.searchParams.hash === getFormHash(f);
										return (
											<SideMenuItem
												key={key}
												label={f.label}
												onClick={() => navigateToForm(f, r.graphLinkId)}
												isSelected={selected}
												rightSection={
													<FormStatusIndicator
														status={status}
														reviewState={r.state}
														isDecisionRequired={isFormDecisionRequired(f.segmentIdentities, r)}
													/>
												}
											/>
										);
									})}
								</>
							)}
							{!isApplicant && (
								<>
									<SideMenuItem
										label={rootEntity.name || 'Unknown entity'}
										onClick={() => navigateToEntity(graphLinkId, rootEntity.graphNodeId)}
										leftSection={<EntityKindIcon kind={rootEntity.entityKind} />}
										rightSection={
											<ConnectionMenu
												connectionKind={connectionKind}
												entityId={rootEntity.graphNodeId}
												parentEntityId={params.accountId}
											/>
										}
										disabled={isApplicant}
									/>
									{connectionForms.map((f) => {
										const key = `${r.graphLinkId}.${f.name}` as const;
										const status = getFormStatuses({
											containers: r.listSegmentReviewContainers,
											segmentIdentities: f.segmentIdentities,
										});

										const selected = params.formKind === f.name && r.graphLinkId === params.connectionId;
										return (
											<SideMenuItem
												key={key}
												label={f.label}
												onClick={() => navigateToForm(f, r.graphLinkId)}
												isSelected={selected}
												rightSection={
													<FormStatusIndicator
														status={status}
														reviewState={r.state}
														isDecisionRequired={isFormDecisionRequired(f.segmentIdentities, r)}
													/>
												}
											/>
										);
									})}
								</>
							)}
						</SideMenu>

						{/*  Render internal connections */}
						{isApplicant && <EntityConnections review={r} fromEntity={rootEntity} />}

						{/*	Render review group connections */}
						{isApplicant && <ReviewGroupConnections review={r} />}
					</Box>
				);
			})}

			{!isApplicant && currentReview && (
				<AddConnectionButton
					review={currentReview}
					connectionKind={params.connectionKind}
					parentEntity={{ kind: account.meta.entityKind, graphId: account.meta.graphNodeId }}
				/>
			)}
		</Stack>
	);
};
