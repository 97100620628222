import React from 'react';

import { createStyles, Input, TextInput as MTextInput } from '@tf/ui';

const useStyles = createStyles(({ fn, colors }) => ({
	disabled: {
		input: {
			color: colors.dark[9] + ' !important',
			cursor: 'text !important',
			borderRadius: '0',
			backgroundColor: fn.lighten(colors.gray[0], 0.2) + ' !important',
			borderColor: colors.gray[2],
			borderWidth: '0 0 1px 0',
			opacity: '1 !important',
			'&:hover': {
				borderColor: colors.gray[2],
				borderWidth: '0 0 1px 0',
			},
		},
	},
}));

export interface TextFieldProps {
	value?: string;
	label?: string;
	onChange: (value: string) => void;
	isReadOnly?: boolean;
	disabled?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
	value,
	label,
	onChange,
	isReadOnly,
	disabled,
}) => {
	const { classes } = useStyles();

	return (
		<Input.Wrapper label={label}>
			<MTextInput
				className={disabled ? classes.disabled : ''}
				disabled={disabled}
				value={value ?? ''}
				onChange={(event) => onChange(event.target.value)}
				readOnly={isReadOnly}
			/>
		</Input.Wrapper>
	);
};
