import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Anchor, createStyles, Flex, Icon, TFDataTable, TFLoadingOverlay, TFText } from '@tf/ui';
import { fmt } from '@tf/utils';

import { useUsers } from '@/core/api/users';
import { getDisplayName } from '@/core/utils';

const useStyles = createStyles(({ colors }) => ({
	twoFactorContainer: {
		alignItems: 'center',
		fill: colors.green[7],
		color: colors.green[7],
	},
	twoFactorIcon: {
		margin: '0 .25rem 0 .75rem',
	},
	anchor: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export const UserTable = () => {
	const { classes } = useStyles();
	const { isPending, data: users, isError } = useUsers();
	const queryClient = useQueryClient();

	useEffect(() => {
		queryClient.invalidateQueries({ queryKey: ['users'] });
	}, [users]);

	if (isPending) {
		return <TFLoadingOverlay h={300} label="Users loading..." />;
	}

	if (isError) {
		return <div>Error, please try again</div>;
	}

	return (
		<TFDataTable<BackendTypes.UserInfoWithClerkData>
			name="users-table"
			data={users ?? []}
			defs={[
				{
					header: 'Name',
					accessorKey: 'name',
					accessorFn: (user) => getDisplayName(user.userInfo),
					size: 150,
					sortingFn: (rowA, rowB) => {
						const rowAName = getDisplayName(rowA.original.userInfo);
						const rowBName = getDisplayName(rowB.original.userInfo);
						return rowAName.localeCompare(rowBName);
					},
					Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
						return (
							<Flex>
								<Anchor
									className={classes.anchor}
									component={Link}
									to={`/settings/users/${row.original.userInfo.userId}`}
									py=".25rem"
									lineClamp={1}
								>
									{getDisplayName(row.original.userInfo)}
								</Anchor>
								{row.original.isTwoFactorEnabled && (
									<Flex className={classes.twoFactorContainer}>
										<Icon.IconCircleKey className={classes.twoFactorIcon} size="1.2rem" />
										<TFText>2FA</TFText>
									</Flex>
								)}
							</Flex>
						);
					},
				},
				{
					header: 'Email',
					accessorKey: 'username',
					size: 100,
					sortingFn: (rowA, rowB) => {
						return rowA.original.userInfo.username.localeCompare(rowB.original.userInfo.username);
					},
					Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
						const { username } = row.original.userInfo;
						return <TFText lineClamp={1}>{username}</TFText>;
					},
				},
				{
					header: 'Last active at',
					accessorKey: 'lastActiveAt',
					size: 100,
					Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
						const { lastActiveAt } = row.original;
						if (!lastActiveAt) return 'N/A';
						return fmt.toDate(lastActiveAt, { preset: 'full_date_with_time' });
					},
				},
			]}
		/>
	);
};
