import type { BackendTypes } from '@tf/api';
import { Box, Center, createStyles, Stack, TFDataTable, TFText, Title, UnstyledButton } from '@tf/ui';
import { fmt, S } from '@tf/utils';

interface Props {
	hits: BackendTypes.AdhocSearchResult['rawResponse'];
	onRowClick: (id: number) => void;
}

const useStyles = createStyles(({ colors }) => ({
	fullName: {
		fontSize: 13,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		color: colors.gray[9],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

interface Hit {
	entityType: string;
	fullName: string;
	countries: string[];
	dateOfBirth: string;
}

export const InstantCheckTable: React.FC<Props> = ({ hits, onRowClick }) => {
	const { classes } = useStyles();

	const profiles = hits
		? // @ts-expect-error FIXME TS error is suppressed for migration, fix it later
		  hits.map((item) => {
				return {
					entityType: item.doc.entity_type,
					fullName: item.doc.name,
					countries: item.doc?.fields
						? item.doc?.fields.filter((i: any) => i.tag === 'country_names').map((i: any) => i.value)
						: [],
					dateOfBirth: item.doc?.fields
						? item.doc?.fields
								.filter((i: any) => i.tag === 'date_of_birth' && i.source === 'complyadvantage')
								.map((i: any) => i.value)
								.join(', ')
						: '',
				};
		  })
		: [];

	return (
		<>
			{/* @ts-expect-error FIXME TS error is suppressed for migration, fix it later */}
			{hits?.length ? (
				<TFDataTable<Hit>
					name="Instant-Check-list"
					data={profiles}
					defs={[
						{
							header: 'Full name',
							accessorKey: 'fullName',
							enableSorting: true,
							size: 200,
							Cell: (info: { row: { original: Hit; id: string } }) => {
								const { fullName } = info.row.original;
								return (
									<UnstyledButton
										className={classes.fullName}
										onClick={() => {
											onRowClick(parseInt(info.row.id));
										}}
									>
										<TFText lineClamp={1}>{fullName || 'N/A'}</TFText>
									</UnstyledButton>
								);
							},
						},
						{
							header: 'Entity type',
							accessorKey: 'entityType',
							size: 100,
							Cell: (info: { row: { original: Hit } }) => {
								const { entityType } = info.row.original;
								return <TFText lineClamp={1}>{S.prettify(entityType) || 'N/A'}</TFText>;
							},
						},
						{
							header: 'Countries',
							accessorKey: 'countries',
							size: 200,
							enableSorting: true,
							Cell: (info: { row: { original: Hit } }) => {
								const { countries } = info.row.original;
								return (
									<div>
										{countries && countries.length
											? countries.map((item: string, index: number) => (
													<Box key={`${item}-${index}`}>
														<TFText>{item || 'N/A'}</TFText>
													</Box>
											  ))
											: 'N/A'}
									</div>
								);
							},
						},
						{
							header: 'Date of birth',
							accessorKey: 'dateOfBirth',
							size: 100,
							Cell: (info: { row: { original: Hit } }) => {
								const { dateOfBirth } = info.row.original;
								if (dateOfBirth)
									return (
										<TFText lineClamp={1}>{fmt.toDate(dateOfBirth, { preset: 'full_date' })}</TFText>
									);
								else
									return (
										<TFText color="dimmed" lineClamp={1}>
											N/A
										</TFText>
									);
							},
						},
					]}
				/>
			) : (
				<Center h={300}>
					<Stack align="center" spacing="sm">
						<Title order={4} color="dimmed">
							No records found
						</Title>
					</Stack>
				</Center>
			)}
		</>
	);
};
