import { Box, Stack, TFCard, Title } from '@tf/ui';

import { useAccountParams, useEntityConnectionLabels, useSelectedAccount } from '@/core/hooks';
import { AddConnectionButton } from '@/components/shared';

export const EmptyConnectionScreen = () => {
	const { connectionKind } = useAccountParams();
	const { meta } = useSelectedAccount();

	const connectionLabels = useEntityConnectionLabels(meta.entityKind);
	const label = connectionLabels[connectionKind].singular;

	return (
		<Box sx={({ breakpoints }) => ({ margin: '.75rem auto', maxWidth: breakpoints.xl })}>
			<TFCard>
				<Stack my="8rem" align="center">
					<Title order={4} color="dimmed">
						{label} not specified
					</Title>
					<AddConnectionButton
						label={label}
						connectionKind={connectionKind}
						parentEntity={{ kind: meta.entityKind, graphId: meta.graphNodeId }}
						buttonProps={{
							fullWidth: false,
							size: 'md',
							variant: 'gradient',
							gradient: { from: 'brand', to: 'blue' },
						}}
					/>
				</Stack>
			</TFCard>
		</Box>
	);
};
