import type { BackendTypes } from '@tf/api';
import { Avatar, TFText } from '@tf/ui';
import { S } from '@tf/utils';

import { useOverviewData } from '@/core/api/overview';

type UserInfoWithId = BackendTypes.UserInfo & { id: string };

interface AssignManagerInput {
	accountId: number;
	selectedIds: string[];
}

const ManagerAvatar = ({ item }: { item: UserInfoWithId }) => {
	return (
		<Avatar color="brand" radius="xl" size={32}>
			{S.abbreviate(item.username)}
		</Avatar>
	);
};

export const AssignedManagersView: React.FC<AssignManagerInput> = (props) => {
	const { data } = useOverviewData();
	const listUsers = data?.listUsers;

	const managers = listUsers ? [...listUsers.map((u) => ({ ...u, id: u.userId }))] : [];
	const selectedManagers = managers.filter((m) => props.selectedIds.includes(m.userId));

	if (!selectedManagers.length) {
		return (
			<TFText color="dimmed" size={13}>
				Not assigned
			</TFText>
		);
	}

	return (
		<Avatar.Group spacing="sm">
			{selectedManagers.map((manager) => (
				<ManagerAvatar key={manager.id} item={manager} />
			))}
		</Avatar.Group>
	);
};
