import { useState } from 'react';

import type { FC } from 'react';

import { Button, Icon, Modal } from '@tf/ui';

import { useSelectedAccount } from '@/core/hooks';
import { AccountStructure } from '@/components/accounts';

export const AccountStructureButton: FC = () => {
	const account = useSelectedAccount();
	const [isOpened, setIsOpened] = useState(false);

	const handleClick = () => {
		setIsOpened(!isOpened);
	};

	return (
		<>
			<Button
				size="sm"
				leftIcon={<Icon.Icon3dCubeSphere size={18} stroke={2.2} />}
				onClick={handleClick}
			>
				Account structure
			</Button>

			<Modal
				title={`Account structure of ${account.meta.name}`}
				opened={isOpened}
				onClose={handleClick}
				fullScreen
			>
				{isOpened && <AccountStructure onClose={handleClick} />}
			</Modal>
		</>
	);
};
