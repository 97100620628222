import React from 'react';

import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Button, Icon, TFNotifier, useDisclosure } from '@tf/ui';

import { AddAccountModal } from './AddAccountModal';

export const AddAccountButton: React.FC = () => {
	const addAccountModal = useDisclosure();

	const navigate = useNavigate();
	const onSuccess = ({ connectedEntityId }: { connectedEntityId: BackendTypes.GraphId }) => {
		TFNotifier.success('Account created');
		navigate(`/accounts/${connectedEntityId}`);
	};

	const onError = (err: Error) => {
		const message = "Couldn't create account";
		Sentry.captureException(new Error(message, { cause: err }));
		TFNotifier.error(message);
	};

	return (
		<>
			<Button leftIcon={<Icon.IconPlus size={18} stroke={2.25} />} onClick={addAccountModal.open}>
				Add account
			</Button>

			<AddAccountModal
				title="Add account"
				opened={addAccountModal.isOpen}
				onClose={addAccountModal.close}
				contentProps={{ onSuccess, onError }}
			/>
		</>
	);
};
