import React from 'react';

import { isFormDecisionRequired } from '@tf/shared';
import { Box, Button, Group, Icon, TFCard } from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import type { ExtendedReviewContainer, FormState } from '@/core/types';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { ReviewDecisionButton } from '@/components/reviews';

import { FormCommentsButton } from './FormCommentsButton';
import { FormStatusLabel } from './FormStatusLabel';

interface Props {
	state: FormState;
	review: ExtendedReviewContainer;
	isLoading: boolean;
	graphId: number;
	segments: FormSegment[];
}

export const FormFooter: React.FC<Props> = ({ state, review, isLoading, graphId, segments }) => {
	const canReviewAccounts = useCurrentUserCapabilityCheck(UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA);
	const canPrepareAccountsData = useCurrentUserCapabilityCheck(
		UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA
	);

	const isReadOnly = segments.every((s) => s.accessMode === 'VIEW');

	const isDecisionButtonVisible =
		canReviewAccounts && review.state !== 'DRAFT' && isFormDecisionRequired(segments, review);

	return (
		<TFCard innerPadding=".75rem 1rem">
			<Group
				spacing="md"
				sx={{
					display: 'flex',
					flexWrap: 'nowrap',
					justifyContent: 'end',
					alignItems: 'center',
				}}
			>
				<Box sx={{ flexGrow: 1, flexShrink: 1, minWidth: 0 }}>
					<FormStatusLabel status={state.statuses} isDecisionRequired={isDecisionButtonVisible} />
				</Box>
				<FormCommentsButton size="md" formId={state.id} graphId={graphId} />
				{isDecisionButtonVisible ? (
					<ReviewDecisionButton
						segments={segments}
						graphId={graphId}
						reviewStatus={state?.statuses?.review || ''}
					/>
				) : null}
				{canPrepareAccountsData && !isReadOnly && (
					<Button loading={isLoading} leftIcon={<Icon.IconRefresh size={16} />} type="submit">
						Save
					</Button>
				)}
			</Group>
		</TFCard>
	);
};
