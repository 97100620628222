import React from 'react';

import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { AccountStructure as SharedAccountStructure } from '@tf/shared';
import { S } from '@tf/utils';

import { useCurrentUser, useSelectedAccount } from '@/core/hooks';
import { isReviewFromConnectionGroup } from '@/core/utils/reviews';

import { useSelectedAccountStructureWithTasks } from './useSelectedAccountStructureWithTasks';

interface Props {
	onClose: () => void;
}

export const AccountStructure: FC<Props> = ({ onClose }) => {
	const accountStructure = useSelectedAccountStructureWithTasks();

	const navigate = useNavigate();
	const userId = useCurrentUser().userId;
	const account = useSelectedAccount();

	return (
		<div style={{ width: '100vw', height: 'calc(100vh - 54px)' }}>
			<SharedAccountStructure
				withLocalStorage
				accountId={account.meta.graphNodeId}
				userId={userId}
				accountStructure={accountStructure}
				withLayoutSelect
				withMinimap
				onNodeClick={(e, node) => {
					e.preventDefault();
					const { data, type } = node;

					if (type === 'account') {
						navigate(`/accounts/${data.meta.accountId}`);
					} else {
						const basePath = `/accounts/${data.meta.accountId}/${S.slugify(data.meta.connectionKind)}`;
						const path = isReviewFromConnectionGroup(account, data.meta.connectionId)
							? basePath
							: `${basePath}/${data.meta.connectionId}/entity/${data.graphId}`;

						navigate(path);
					}
					onClose();
				}}
			/>
		</div>
	);
};
