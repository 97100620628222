import { queryOptions, useQuery } from '@tanstack/react-query';

import type { BackendTypes } from '@tf/api';
import { BackendClient } from '@tf/api';

export const entityLookupQueryOptions = (entityKind: BackendTypes.EntityKind, namePrefix: string) => {
	return queryOptions({
		queryKey: ['entities', 'lookup', { entityKind, namePrefix }],
		queryFn: async () => {
			if (namePrefix.length === 0) {
				return [];
			}

			const result = await BackendClient.getInstance().entityLookup({
				entityKind,
				namePrefix,
			});

			return (await result.json()) as BackendTypes.EntityLookupData;
		},
	});
};

export const useEntityLookupQuery = (entityKind: BackendTypes.EntityKind, namePrefix: string) => {
	return useQuery(entityLookupQueryOptions(entityKind, namePrefix));
};
