import { useEffect, useState } from 'react';

import type { FC } from 'react';

import { Box, Button, createStyles, DatePicker, Popover, TFText } from '@tf/ui';
import { D, fmt } from '@tf/utils';

import { DateValue } from '@/components/shared/DateValue';

const useStyles = createStyles(({ colors, fontSizes }) => {
	return {
		presetsLabel: {
			color: colors.gray[5],
			fontWeight: 600,
			fontSize: fontSizes.xs,
			letterSpacing: '.5px',
		},
		presets: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		presetButton: {
			padding: 0,

			'&:hover': {
				backgroundColor: 'transparent',
				color: colors.brand[7],
			},
		},
		overdueText: {
			color: colors.red[6],
			cursor: 'pointer',
			fontWeight: 600,
		},
	};
});

const presets = [
	[3, 'months'],
	[6, 'months'],
	[1, 'year'],
	[2, 'years'],
] as const;

interface NextReviewDateProps {
	baseDate: string | undefined;
	date: string | undefined;
	isLoading: boolean;
	onSave: (newDate: string) => Promise<any>;
}

export const NextReviewDate: FC<NextReviewDateProps> = ({ baseDate, date, isLoading, onSave }) => {
	const { classes } = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Date>();

	const closePopover = () => setIsOpen(false);

	useEffect(() => {
		if (isOpen && date) {
			setSelectedDate(new Date(date));
		}
	}, [date, isOpen]);

	const onSaveChanges = async () => {
		if (!selectedDate) {
			return;
		}

		const nextValue = fmt.toDate(selectedDate, { preset: 'date_struct' });
		await onSave(nextValue);
		closePopover();
	};

	const handleDateChange = (newDate: Date) => {
		if (newDate) {
			setSelectedDate(newDate);
		}
	};

	return (
		<Popover withArrow opened={isOpen} onClose={closePopover}>
			<Popover.Target>
				{date && D.getOverdueDaysCount(date) ? (
					<TFText className={classes.overdueText} onClick={() => setIsOpen(!isOpen)}>
						Overdue {D.getOverdueDaysCount(date)} {D.getOverdueDaysCount(date) > 1 ? 'days' : 'day'}
					</TFText>
				) : (
					<DateValue value={date} onClick={() => setIsOpen(!isOpen)} />
				)}
			</Popover.Target>

			<Popover.Dropdown>
				<Box mb=".5rem">
					<TFText className={classes.presetsLabel}>Schedule a recheck afterwards</TFText>
					<Box className={classes.presets}>
						{presets.map(([value, unit]) => {
							const label = `${value} ${unit}`;

							const setDate = () => {
								const startDate = baseDate ? new Date(baseDate) : new Date();
								const presetDate = D.add(startDate, value, unit);

								setSelectedDate(presetDate);
							};

							return (
								<Button
									key={label}
									className={classes.presetButton}
									variant="subtle"
									size="xs"
									onClick={setDate}
								>
									{label}
								</Button>
							);
						})}
					</Box>
				</Box>

				<DatePicker
					locale="en"
					date={selectedDate}
					value={selectedDate}
					onDateChange={handleDateChange}
					onChange={handleDateChange}
				/>

				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button fullWidth variant="light" size="sm" mr=".75rem" onClick={closePopover}>
						Cancel
					</Button>
					<Button
						loading={isLoading}
						loaderProps={{ size: 14 }}
						fullWidth
						size="sm"
						disabled={!selectedDate}
						onClick={onSaveChanges}
					>
						Save
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
