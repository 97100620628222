import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { BackendClient } from '@tf/api';
import { Button, Icon, TFModals, TFNotifier } from '@tf/ui';

import { useUsers } from '@/core/api/users';
import { ConfirmationModal } from '@/components/shared';

interface Props {
	userId: string;
}

export const UserDeleteButton: React.FC<Props> = ({ userId }) => {
	const api = BackendClient.getInstance();
	const users = useUsers();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const suspendUser = async () => {
		try {
			setIsLoading(true);
			await api.deleteUser(userId);
			await users.refetch();
			TFNotifier.info('User suspended');
			navigate('/settings/users');
		} catch {
			TFNotifier.error('Unknown error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleSuspend = () =>
		TFModals.open({
			children: (
				<ConfirmationModal
					title="Action confirmation"
					message="Are you sure you want to delete this user?"
					confirmText="Delete user"
					onConfirm={suspendUser}
				/>
			),
		});

	return (
		<Button
			variant="light"
			color="red"
			onClick={handleSuspend}
			loading={isLoading}
			leftIcon={<Icon.IconTrash size={16} stroke={2.5} />}
		>
			Delete user
		</Button>
	);
};
