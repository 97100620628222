import React from 'react';

import { Badge, createStyles } from '@tf/ui';

const useStyles = createStyles({
	wrapper: {
		width: '16px',
		height: '16px',
		padding: 0,
		fontSize: '.625rem',
		fontWeight: 600,
		color: 'white',
		background: '#1b63ee',
	},
});

export const CounterBadge: React.FC<{ value: number }> = ({ value }) => {
	const { classes } = useStyles();

	return (
		<Badge fullWidth={false} className={classes.wrapper}>
			{value}
		</Badge>
	);
};
