import React from 'react';

import { useSelector } from '@datagrid/state';
import { Navigate } from 'react-router-dom';

import { extractForms, getFormHash, getSegmentsForReview } from '@tf/shared';
import { Center, Stack, TFCard, Title } from '@tf/ui';
import { S, T } from '@tf/utils';

import { useAccountParams, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';

export const NavigateToFirstFormRoute: React.FC = () => {
	const account = useSelectedAccount();
	const { connectionKind } = useAccountParams();
	const formDefs = useSelector(() => appStore.defs.get().forms);

	const reviews = account.reviews.filter((r) => r.connectionKind === connectionKind);
	const currentReview = reviews.find((r) => r.connectionKind === connectionKind);

	T.assertNotNullish(currentReview, 'review not found');

	const segmentIdentities = getSegmentsForReview(account.meta, currentReview).filter((c) => {
		return [account.meta.graphNodeId, currentReview.graphNodeId, currentReview.graphLinkId].includes(
			c.graphId
		);
	});

	const forms = extractForms({ formDefs, segmentIdentities });
	const firstForm = forms[0];

	if (connectionKind === 'APPLICANT')
		return (
			<Navigate
				to={`${currentReview.graphLinkId}/${firstForm.name}?hash=${getFormHash(firstForm)}`}
				replace
			/>
		);

	return (
		<TFCard>
			<Center h={300}>
				<Stack spacing={12}>
					<Title order={4} color="dimmed">
						Please select {S.prettify(connectionKind).toLowerCase()}
					</Title>
				</Stack>
			</Center>
		</TFCard>
	);
};
