import React from 'react';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createStyles,
	Flex,
	Icon,
	Menu,
	TFDataTable,
	TFNotifier,
	TFText,
	Tooltip,
} from '@tf/ui';

import { useSetCraAutoAssigneesGroupsMutation } from '@/core/api/craSettings';
import { useCurrentUser } from '@/core/hooks';
import { capabilities, capabilitiesIcons, getDisplayName } from '@/core/utils';
import { UserAvatar } from '@/components/shared/UserAvatar';

const useStyles = createStyles(({ colors }) => ({
	icon: {
		color: colors.gray[6],
	},
	textContainer: {
		maxWidth: '800px',
		color: '#475467',
		fontWeight: 400,
		fontSize: '14px',
	},
	labelText: {
		fontWeight: 600,
		fontSize: '16px',
		marginLeft: '8px',
	},
	container: {
		marginTop: '32px',
	},
	flexContainer: {
		alignItems: 'center',
		marginBottom: '12px',
	},
	menuItemContainer: {
		display: 'flex',
		alignItems: 'center',
		width: 240,
		padding: '.15rem .5rem',
	},
	menuItemText: {},
}));

interface Props {
	riskLevelMap: BackendTypes.AssigneesManagersRiskLevelMap;
	label: string;
	icon: React.ReactElement;
	riskLevel: BackendTypes.OverviewAccountRiskLevel;
	listUsers: BackendTypes.UserInfoWithClerkData[];
}

export const AutoAssignedTable: React.FC<Props> = ({
	riskLevelMap,
	listUsers,
	label,
	icon,
	riskLevel,
}) => {
	const { classes } = useStyles();
	const currentUser = useCurrentUser();
	const userIdMap = listUsers.reduce<Record<string, BackendTypes.UserInfoWithClerkData>>((acc, user) => {
		acc[user.userInfo.userId] = user;
		return acc;
	}, {});
	const usersIds = riskLevelMap[riskLevel] ?? [];
	const usersWithUserInfo = usersIds.map((userId) => userIdMap[userId]);
	const selectedManagers = usersWithUserInfo.map((user) => user.userInfo.userId) ?? [];
	const availableManagers = listUsers
		?.filter((u) => !selectedManagers.includes(u.userInfo.userId))
		.sort((userA, userB) => {
			return getDisplayName(userA.userInfo).localeCompare(getDisplayName(userB.userInfo));
		});

	const setCraAutoAssigneesGroupsMutation = useSetCraAutoAssigneesGroupsMutation();
	const handleAddUser = async (id: string) => {
		await setCraAutoAssigneesGroupsMutation.mutateAsync(
			{ ...riskLevelMap, [riskLevel]: [...(riskLevelMap[riskLevel] ?? []), id] },
			{
				onSuccess: () => {
					TFNotifier.success('User added to risk level group ');
				},
				onError: () => {
					TFNotifier.error('Failed to add user');
				},
			}
		);
	};

	const handleRemoveUser = async (id: string) => {
		await setCraAutoAssigneesGroupsMutation.mutateAsync(
			{ ...riskLevelMap, [riskLevel]: (riskLevelMap[riskLevel] ?? []).filter((u) => u !== id) },
			{
				onSuccess: () => {
					TFNotifier.success('User removed from risk level group ');
				},
				onError: () => {
					TFNotifier.error('Failed to remove user');
				},
			}
		);
	};

	return (
		<Box className={classes.container}>
			<Flex className={classes.flexContainer}>
				{icon}
				<TFText className={classes.labelText}>{label}</TFText>
			</Flex>
			<TFDataTable<BackendTypes.UserInfoWithClerkData>
				name={label}
				data={usersWithUserInfo}
				hideSearchbar
				defs={[
					{
						header: 'Name',
						accessorKey: 'name',
						size: 150,
						sortingFn: (rowA, rowB) => {
							const rowAName = getDisplayName({
								firstName: rowA.original.userInfo.firstName,
								lastName: rowA.original.userInfo.lastName,
								username: 'N/A',
							});
							const rowBName = getDisplayName({
								firstName: rowB.original.userInfo.firstName,
								lastName: rowB.original.userInfo.lastName,
								username: 'N/A',
							});
							return rowAName.localeCompare(rowBName);
						},
						Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
							return (
								<TFText py=".25rem" lineClamp={1}>
									{getDisplayName({
										firstName: row.original.userInfo.firstName,
										lastName: row.original.userInfo.lastName,
										username: 'N/A',
									})}
								</TFText>
							);
						},
					},
					{
						header: 'Email',
						accessorKey: 'email',
						size: 100,
						Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
							const { emailAddresses } = row.original;
							return <TFText lineClamp={1}>{emailAddresses[0] ?? 'N/A'}</TFText>;
						},
					},
					{
						header: 'Capabilities',
						accessorKey: 'capabilities',
						size: 100,
						Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
							const { listRoleCapabilities } = row.original.userInfo;
							return (
								<Flex>
									{listRoleCapabilities.map((cap) => (
										<Tooltip className={classes.icon} key={cap} label={capabilities[cap]}>
											{capabilitiesIcons[cap]}
										</Tooltip>
									))}
								</Flex>
							);
						},
					},
					{
						header: 'Action',
						accessorKey: 'action',
						size: 100,
						enableSorting: false,
						enableResizing: false,
						Cell: ({ row }: { row: { original: BackendTypes.UserInfoWithClerkData } }) => {
							return (
								<Button
									variant="default"
									disabled={setCraAutoAssigneesGroupsMutation.isPending}
									onClick={() => handleRemoveUser(row.original.userInfo.userId)}
									sx={{ borderColor: '#E2E8F0' }}
									size="xs"
								>
									Remove
								</Button>
							);
						},
					},
				]}
			/>

			<Menu
				shadow="md"
				withinPortal
				withArrow
				position="bottom-start"
				styles={{
					item: { padding: 0 },
					dropdown: { padding: 0, overflow: 'visible' },
				}}
			>
				<Menu.Target>
					<Button variant="light" mt="12px" p="8px">
						<Icon.IconPlus size="16px" />
						<TFText size="14px" ml="4px">
							Add user
						</TFText>
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					{availableManagers?.length ? (
						availableManagers.map((u) => {
							return (
								<Menu.Item key={u.userInfo.userId} onClick={() => handleAddUser(u.userInfo.userId)}>
									<Box className={classes.menuItemContainer}>
										<UserAvatar userInfo={u.userInfo} />
										<TFText size="xs" sx={{ flex: 1, marginLeft: '.5rem' }} lineClamp={1}>
											{getDisplayName(u.userInfo)}
											{currentUser.userId === u.userInfo.userId && ' (You) '}
										</TFText>
									</Box>
								</Menu.Item>
							);
						})
					) : (
						<Box className={classes.menuItemContainer}>
							<TFText size="md">No users available</TFText>
						</Box>
					)}
				</Menu.Dropdown>
			</Menu>
		</Box>
	);
};
