import React from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TFPageContent, TFPageHeader } from '@tf/shared';
import { Box, createStyles, Tabs } from '@tf/ui';
import { T } from '@tf/utils';

import { appStore } from '@/core/stores';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';

const TABS = [
	{ href: '/settings/cra', title: 'CRA settings' },
	{ href: '/settings/cra/auto-assigned', title: 'Auto-assigned settings' },
] as const;

const useStyles = createStyles(({ colorScheme, colors }) => ({
	tabs: {
		marginTop: '-.75rem',
	},
	tabsList: {
		borderBottomWidth: 1,
		borderColor: colorScheme === 'light' ? colors.gray[2] : colors.dark[4],
	},
	tab: {
		display: 'flex',
		gap: '.35rem',
	},
}));

export const CRASettingsLayout: React.FC = () => {
	const { classes } = useStyles();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const tenant = appStore.tenant?.get();
	T.assertNotNullish(tenant, 'no tenant in appStore');
	const canManageUsers = useCurrentUserCapabilityCheck(UserCapabilities.CAN_MANAGE_USERS);

	return (
		<>
			<TFPageHeader
				title="CRA settings"
				tenant={tenant}
				breadcrumbs={[
					...(canManageUsers ? [{ href: '/settings/users', title: 'Workspace settings' }] : []),
					TABS.find((t) => t.href === pathname) ?? TABS[0],
				]}
			/>
			<TFPageContent>
				<Tabs
					value={pathname}
					onTabChange={(value) => {
						if (value) {
							navigate(value);
						}
					}}
					className={classes.tabs}
				>
					<Tabs.List className={classes.tabsList}>
						{TABS.map(({ href, title }) => (
							<Tabs.Tab key={href} value={href}>
								<Box className={classes.tab}>{title}</Box>
							</Tabs.Tab>
						))}
					</Tabs.List>
					<Box pt="0.75rem">
						<Outlet />
					</Box>
				</Tabs>
			</TFPageContent>
		</>
	);
};
