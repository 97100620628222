import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Avatar, Box, Icon, Menu, TFText, Tooltip, UnstyledButton } from '@tf/ui';

import { useCurrentUser } from '@/core/hooks';
import { UserCapabilities } from '@/core/types';
import { getDisplayName } from '@/core/utils';
import { UserAvatar } from '@/components/shared/UserAvatar';

export type ManagersFilterFn = (user: BackendTypes.UserInfo) => boolean;

const defaultManagersFilter: ManagersFilterFn = (user) => {
	return user.listRoleCapabilities.some((capability) => {
		return (
			capability === UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA ||
			capability === UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA
		);
	});
};

interface Props {
	selectedIds: string[];
	managers: BackendTypes.UserInfo[];
	managersFilter?: ManagersFilterFn;
	onChange: (selectedIds: string[]) => void;
}

export const AssignedManagersSelect: React.FC<Props> = ({
	selectedIds,
	managers,
	managersFilter = defaultManagersFilter,
	onChange,
}) => {
	const currentUser = useCurrentUser();

	const availableManagers = managers.filter(managersFilter).sort((userA, userB) => {
		return getDisplayName(userA).localeCompare(getDisplayName(userB));
	});

	const selectedManagers = new Map<string, BackendTypes.UserInfo>();
	availableManagers.forEach((manager) => {
		const isSelected = selectedIds.includes(manager.userId);
		if (isSelected) {
			selectedManagers.set(manager.userId, manager);
		}
	});

	const handleChange = (selectedId: string) => {
		const newSelectedIds = selectedManagers.has(selectedId)
			? selectedIds.filter((id) => id !== selectedId)
			: [...selectedIds, selectedId];

		onChange(newSelectedIds);
	};

	return (
		<Menu
			shadow="md"
			placement="bottom-end"
			withinPortal={true}
			withArrow
			styles={{
				item: { padding: 0 },
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dropdown: { padding: 0, zIndex: '1001 !important', overflow: 'visible' },
			}}
		>
			<Menu.Target>
				<UnstyledButton>
					{selectedManagers.size > 0 ? (
						<Avatar.Group spacing="sm">
							{Array.from(selectedManagers.values()).map((manager) => {
								return (
									<Tooltip key={manager.userId} label={getDisplayName({ ...manager })}>
										<UserAvatar userInfo={manager} />
									</Tooltip>
								);
							})}
						</Avatar.Group>
					) : (
						<TFText
							color="dimmed"
							sx={({ colors }) => ({
								'&:hover': { color: colors.brand[6], textDecoration: 'underline' },
							})}
							size={13}
						>
							Not assigned
						</TFText>
					)}
				</UnstyledButton>
			</Menu.Target>
			<Menu.Dropdown>
				{availableManagers.map((u) => {
					const isSelected = selectedManagers.has(u.userId);
					return (
						<Menu.Item key={`user.${u.userId}`} onClick={() => handleChange(u.userId)}>
							<Box
								sx={({ colors }) => ({
									display: 'flex',
									alignItems: 'center',
									width: 240,
									padding: '.15rem .5rem',
									...(isSelected && {
										backgroundColor: colors.gray[0],
									}),
								})}
							>
								<UserAvatar userInfo={u} />
								<TFText size="xs" sx={{ flex: 1, marginLeft: '.5rem' }} lineClamp={1}>
									{getDisplayName(u)}
									{currentUser.userId === u.userId && ' (You) '}
								</TFText>
								{isSelected && (
									<Box
										sx={({ colors }) => ({
											color: colors.brand[6],
											height: 18,
										})}
									>
										<Icon.IconCheck size={16} />
									</Box>
								)}
							</Box>
						</Menu.Item>
					);
				})}
			</Menu.Dropdown>
		</Menu>
	);
};
