import React, { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import type { FilterItem } from '@tf/ui';
import {
	Box,
	Center,
	createStyles,
	FilterButton,
	Flex,
	SearchInput,
	Stack,
	TFLoadingOverlay,
	TFText,
	Timeline,
	Title,
} from '@tf/ui';
import { fmt, S } from '@tf/utils';

import { useAccountEventsLog } from '@/core/api/accountEvenstLog';
import { useAccountParams } from '@/core/hooks';

const ACCOUNT_STATUS_MESSAGES: Record<string, string> = {
	ACCOUNT_CREATED: 'New account has been created',
	ACCOUNT_CLOSED: 'The account has now been closed',
	ACCOUNT_ACTIVATED: 'The account has been activated',
	ACCOUNT_REJECTED: 'New client application has been rejected',
	ACCOUNT_SUSPENDED: 'The account has been suspended',
	ACCOUNT_USER_ASSIGNED: 'New manager has been assigned to the account',
	ACCOUNT_USER_UNASSIGNED: 'Manager is no longer assigned to the account',
	REVIEW_PENDING: 'The account is pending Compliance review',
	REVIEW_UPDATE_REQUIRED: 'More information required to complete account review',
	REVIEW_VERIFIED: 'The account has been verified',
	REVIEW_COMMENTED: 'New comment has been added',
	KYC_REVIEW_DATE_REACHED: 'KYC re-verification is overdue',
	CLIENT_CASE_REQUEST_SENT: 'New case has been opened',
	CLIENT_CASE_REQUEST_REVOKED: 'The case has been revoked',
	CLIENT_CASE_REQUEST_VIEWED: 'The case has been viewed by the client',
	CLIENT_CASE_REQUEST_UPDATED: 'The case has been updated by the client',
	CLIENT_CASE_REQUEST_CLOSED: 'The case has been closed',
	CRA_CALCULATED: 'Customer risk rating has been calculated',
	USER_INVITATION_REVOKED: 'The user invitation has been revoked',
};

const availableFilters = [
	{
		name: 'event',
		label: 'Events',
		type: 'select',
		value: Object.entries(ACCOUNT_STATUS_MESSAGES).map(([key, value]) => ({
			label: value,
			value: key,
		})),
	},
	{
		name: 'date',
		label: 'Date range',
		type: 'date',
		value: [null, null],
	},
];

const useFilteredEvents = (events?: BackendTypes.AccountEventLogData) => {
	const [searchValue, setSearchValue] = useState('');
	const [filters, setFilters] = useState<{ id: string; value: unknown }[]>([]);

	if (!events) return;
	const filteredEvents = events.filter((event) => {
		if (!filters.length && !searchValue) return true;

		const eventFilters = filters.find((f) => f.id === 'event');
		const dateFilters = filters.find((f) => f.id === 'date');

		let matchesEventKind = true;
		let matchesDateRange = true;
		let matchesSearch = true;

		if (eventFilters) {
			matchesEventKind = eventFilters.value === event.eventKind;
		}

		if (dateFilters && Array.isArray(dateFilters.value)) {
			const [startDate, endDate] = dateFilters.value.map((dateStr) => new Date(dateStr));
			const eventDate = new Date(event.createdAt);
			eventDate.setHours(0, 0, 0, 0);
			matchesDateRange = eventDate >= startDate && eventDate <= endDate;
		}

		if (searchValue) {
			const eventLabel = ACCOUNT_STATUS_MESSAGES[event.eventKind]?.toLowerCase();
			matchesSearch = eventLabel?.includes(searchValue.toLowerCase());
		}

		return matchesEventKind && matchesDateRange && matchesSearch;
	});

	return { filteredEvents, searchValue, setSearchValue, filters, setFilters };
};

const useStyles = createStyles(() => ({
	timelineItem: {
		'& > :not(:first-of-type)': {
			marginTop: '1.2rem !important',
		},
		'& > ::before': {
			bottom: 'calc(1.2rem * -1) !important',
		},
	},
}));

export const AccountEventLog = () => {
	const { accountId } = useAccountParams();

	const { data: events, isPending: isEventsLogPending } = useAccountEventsLog(accountId);
	const sortedEvents = events?.slice().sort((a, b) => {
		const createdAtA = new Date(b.createdAt).getTime();
		const createdAtB = new Date(a.createdAt).getTime();
		if (a.eventKind !== 'ACCOUNT_CREATED' && b.eventKind === 'ACCOUNT_CREATED') {
			return -1;
		}
		return createdAtA - createdAtB;
	});
	const eventsFilters = useFilteredEvents(sortedEvents);
	const { classes } = useStyles();

	if (isEventsLogPending || !eventsFilters) {
		return <TFLoadingOverlay h={300} size={24} />;
	}

	const { setSearchValue, filteredEvents, setFilters, filters, searchValue } = eventsFilters;

	return (
		<Box p={'1rem'}>
			<Box mb=".75rem">
				<Flex m="0 0 1.5rem">
					<SearchInput searchValue={searchValue} onSearchChange={(v) => setSearchValue(v)} />
					<FilterButton
						popoverPosition={'top-end'}
						availableFilters={availableFilters as FilterItem[]}
						activeFilters={filters}
						setFilters={setFilters}
					/>
				</Flex>
			</Box>

			<Timeline className={classes.timelineItem} bulletSize={16} lineWidth={2}>
				{!filteredEvents.length && (
					<Center h={300}>
						<Stack align="center" spacing="sm">
							<Title order={4} color="dimmed">
								No events found
							</Title>
						</Stack>
					</Center>
				)}
				{filteredEvents.map((event, index) => (
					<Timeline.Item
						lineActive
						active
						key={index}
						title={ACCOUNT_STATUS_MESSAGES[event.eventKind] || S.prettify(event.eventKind)}
					>
						<TFText color="dimmed" size="sm">
							{fmt.toDate(event.createdAt, { preset: 'full_date_with_time' })}
						</TFText>
					</Timeline.Item>
				))}
			</Timeline>
		</Box>
	);
};
