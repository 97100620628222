import { useEffect } from 'react';

import * as amplitude from '@amplitude/analytics-browser';
import { useUser } from '@clerk/clerk-react';
import { useSelector } from '@datagrid/state';

import { configStore } from '@/core/stores';

export const AmplitudeInitialization = () => {
	const config = useSelector(() => configStore.get());

	useEffect(() => {
		if (config.amplitude?.api_key) {
			amplitude.init(config.amplitude.api_key, {
				appVersion: import.meta.env.VITE_APP_VERSION,
				defaultTracking: {
					pageViews: true,
					sessions: true,
					formInteractions: false,
					fileDownloads: false,
				},
			});
		}
	}, [config.amplitude?.api_key]);

	const { user } = useUser();

	useEffect(() => {
		if (!config.amplitude?.api_key) {
			return;
		}

		if (user) {
			amplitude.setUserId(`${config.instance_id}:${String(user.id)}`);
		} else {
			amplitude.setUserId(undefined);
		}
	}, [config.amplitude?.api_key, config.instance_id, user]);

	return null;
};
