import React from 'react';

import { useSelector } from '@datagrid/state';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import type { ExtendedFormDefinition } from '@tf/shared';
import { extractForms, getFormHash, getFormStatuses, isFormDecisionRequired } from '@tf/shared';
import { Box, Stack } from '@tf/ui';

import { useAccountParams } from '@/core/hooks';
import { appStore } from '@/core/stores';
import type { ExtendedReviewContainer } from '@/core/types';
import { useNavigateToEntity } from '@/core/utils/routes';
import {
	EntityConnections,
	EntityKindIcon,
	FormStatusIndicator,
	SideMenu,
	SideMenuItem,
} from '@/components/shared';
import { BulkReviewButton } from '@/components/shared/BulkReviewButton';

interface Props {
	entity: BackendTypes.Entity;
	connectionKind: string;
	review: ExtendedReviewContainer;
	fromGraphId: number;
}

export const EntityWidget: React.FC<Props> = ({ review, entity }) => {
	const navigate = useNavigate();
	const params = useAccountParams();
	const { navigateToEntity, getEntityPath } = useNavigateToEntity();
	const name = review.listEntities.find((e) => e.graphNodeId === entity.graphNodeId)?.name || 'Unknown';

	// * Extract forms from segments
	const formDefs = useSelector(() => appStore.defs.get().forms);
	const forms = extractForms({
		formDefs,
		segmentIdentities: entity.listSegments.map((s) => s.SegmentIdentity),
	});

	// * Navigation handler
	const navigateToForm = (form: ExtendedFormDefinition<BackendTypes.FormDefinition>) => {
		window.scrollTo(0, 0);
		const hash = getFormHash(form);
		return navigate(
			`${getEntityPath(review.graphLinkId, review.connectionKind, entity.graphNodeId)}/${
				form.name
			}?hash=${hash}`
		);
	};

	return (
		<Stack spacing={0} mr=".75rem">
			<BulkReviewButton graphId={entity.graphNodeId} review={review} forms={forms} />

			<SideMenu>
				<SideMenuItem
					key={`${review.connectionKind}.${entity.graphNodeId}.header`}
					label={name}
					leftSection={<EntityKindIcon kind={entity.entityKind} />}
					onClick={() => navigateToEntity(review.graphLinkId, review.connectionKind, entity.graphNodeId)}
				/>

				{forms.map((form) => {
					const key = `${review.graphLinkId}.${form.name}` as const;
					const status = getFormStatuses({
						containers: review.listSegmentReviewContainers,
						segmentIdentities: form.segmentIdentities,
					});
					return (
						<SideMenuItem
							key={key}
							label={form.label}
							isSelected={form.name === params.formKind && entity.graphNodeId === params.entityId}
							rightSection={
								<FormStatusIndicator
									status={status}
									reviewState={review.state}
									isDecisionRequired={isFormDecisionRequired(form.segmentIdentities, review)}
								/>
							}
							onClick={() => navigateToForm(form)}
						/>
					);
				})}
			</SideMenu>
			<Box>
				<EntityConnections review={review} fromEntity={entity} isExternal />
			</Box>
		</Stack>
	);
};
