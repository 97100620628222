import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	createStyles,
	Icon,
	TFDataTable,
	TFText,
	Tooltip,
	UnstyledButton,
	useDebouncedValue,
	useLocalStorage,
} from '@tf/ui';
import { S } from '@tf/utils';

import { useMonitoringQuery } from '@/core/api/monitoring';

const useStyles = createStyles(({ colors }) => ({
	accountName: {
		fontSize: 13,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
}));

const sortingNamesMap: Record<string, string> = {
	entityKind: 'ENTITY_TYPE',
	totalHits: 'TOTAL_HITS',
	totalMatches: 'TOTAL_MATCHES',
	matchStatus: 'STATUS',
};

export const MonitoringTable = () => {
	const { classes } = useStyles();

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: 'monitoring-table-search',
		getInitialValueInEffect: false,
	});
	const [debouncedSearch] = useDebouncedValue(searchValue, 500);

	const [pagination, setPagination] = useLocalStorage<{ pageSize: number; pageIndex: number }>({
		defaultValue: { pageSize: 10, pageIndex: 0 },
		key: 'monitoring-table-pagination',
		getInitialValueInEffect: false,
	});

	const [sortingState, setSortingState] = useLocalStorage<
		{
			desc: boolean;
			id: string;
		}[]
	>({
		defaultValue: [{ desc: true, id: 'ENTITY_TYPE' }],
		key: `monitoring-table-sorting`,
		getInitialValueInEffect: false,
	});
	const sortingParams =
		sortingState.length > 0
			? {
					sortBy: sortingNamesMap[sortingState[0].id] as BackendTypes.MonitoringSortBy | undefined,
					sortOrder: sortingState[0].desc ? 'DESC' : ('ASC' as BackendTypes.MonitoringSortOrder),
			  }
			: undefined;

	const { data: monitoringList, isPending } = useMonitoringQuery({
		limit: pagination.pageSize,
		page: pagination.pageIndex + 1,
		query: debouncedSearch,
		...sortingParams,
	});

	return (
		<TFDataTable<BackendTypes.MonitoringSearch>
			name="monitoring-table"
			isLoading={isPending}
			serverPagination={
				monitoringList
					? {
							...pagination,
							rowCount: monitoringList.count,
							onPaginationChange: setPagination,
					  }
					: undefined
			}
			serverSearch={{
				state: searchValue,
				onSearchChange: setSearchValue,
			}}
			serverSorting={{
				onSortingChange: setSortingState,
				state: sortingState,
			}}
			data={monitoringList?.entities ?? []}
			defs={[
				{
					header: 'Name',
					accessorKey: 'name',
					size: 400,
					enableSorting: false,
					Cell: ({ row }) => {
						const { graphNodeId, name } = row.original;
						return (
							<UnstyledButton
								component={Link}
								to={`/monitoring/${graphNodeId}`}
								className={classes.accountName}
							>
								<TFText lineClamp={1}>{name || 'N/A'}</TFText>
							</UnstyledButton>
						);
					},
				},
				{
					header: 'Entity type',
					accessorKey: 'entityKind',
					size: 200,
					Cell: ({ row }: { row: { original: BackendTypes.MonitoringSearch } }) => {
						const { entityKind } = row.original;
						return <TFText lineClamp={1}>{S.prettify(entityKind) || 'N/A'}</TFText>;
					},
				},
				{
					header: 'Total hits',
					accessorKey: 'totalHits',
					size: 100,
					Cell: ({ row }: { row: { original: BackendTypes.MonitoringSearch } }) => {
						const { totalHits } = row.original;
						return <TFText lineClamp={1}>{totalHits}</TFText>;
					},
				},
				{
					header: 'Total matches',
					accessorKey: 'totalMatches',
					size: 100,
					Cell: ({ row }: { row: { original: BackendTypes.MonitoringSearch } }) => {
						const { totalMatches } = row.original;
						return <TFText lineClamp={1}>{totalMatches}</TFText>;
					},
				},
				{
					header: 'Status',
					accessorKey: 'matchStatus',
					size: 200,
					Cell: ({ row }: { row: { original: BackendTypes.MonitoringSearch } }) => {
						const { matchStatus } = row.original;
						return <TFText lineClamp={1}>{S.prettify(matchStatus) || 'N/A'}</TFText>;
					},
				},
				{
					header: 'Is changed',
					accessorKey: 'unacknowledgedChangesCount',
					size: 200,
					Cell: ({ row }: { row: { original: BackendTypes.MonitoringSearch } }) => {
						const { unacknowledgedChangesCount: count } = row.original;
						if (!count) {
							return 'No changes';
						}

						return (
							<Tooltip label={`${count} ${count > 1 ? 'changes' : 'change'}`}>
								<Icon.IconBell size="20" color="#2970FF" />
							</Tooltip>
						);
					},
				},
			]}
		/>
	);
};
