import React from 'react';

import { createStyles, Icon, Menu, TFNotifier, TFText, UnstyledButton } from '@tf/ui';

import { useDisconnectEntityMutation } from '@/core/api/connections';

interface Props {
	parentEntityId: number;
	connectionKind: string;
	entityId: number;
}

const useStyles = createStyles(({ colors, radius }) => ({
	actionButton: {
		width: 24,
		height: 24,
		marginRight: '.1rem',
		borderRadius: radius.xl,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'&:hover': {
			backgroundColor: colors.brand[0],
		},
	},
}));

export const ConnectionMenu: React.FC<Props> = ({ parentEntityId, connectionKind, entityId }) => {
	const { classes } = useStyles();

	const disconnectEntityMutation = useDisconnectEntityMutation();
	const handleRemoveConnection = () => {
		disconnectEntityMutation.mutate(
			{
				parentEntityId,
				connectionKind,
				entityId,
			},
			{
				onSuccess: () => {
					TFNotifier.success('Connection removed');
				},
				onError: () => {
					TFNotifier.error('Connection removing failed');
				},
			}
		);
	};

	return (
		<Menu shadow="md" position="right">
			<Menu.Target>
				<UnstyledButton className={classes.actionButton}>
					<Icon.IconDotsVertical size={14} />
				</UnstyledButton>
			</Menu.Target>

			<Menu.Dropdown>
				<Menu.Item
					closeMenuOnClick
					icon={<Icon.IconTrash size={14} />}
					p=".375rem 1.5rem"
					color="red"
					onClick={handleRemoveConnection}
				>
					<TFText size={13}>Remove connection</TFText>
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
};
