import { useState } from 'react';

import type { ChangeEvent, FC } from 'react';

import { type BackendTypes } from '@tf/api';
import { Box, Button, Checkbox, Stack, TextInput, TFCard, TFField, TFNotifier, TFSection } from '@tf/ui';

import { useUpdateUserMutation } from '@/core/api/users';
import { useCurrentUser } from '@/core/hooks';
import { UserCapabilities } from '@/core/types';
import { getCapabilities } from '@/core/utils';

const capabilitiesDescription: Record<BackendTypes.RoleCapabilityValue, string> = {
	[UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA]:
		'Create new client accounts, add and edit client information, submit accounts for review, add comments, assign other managers, update client status across customer lifecycle',
	[UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA]:
		'Review client profiles, manage customer risk assessment, perform perpetual CDD, approve or reject client applications, add comments, assign other managers',
	[UserCapabilities.CAN_MANAGE_MONITORING]:
		'Use real-time third-party databases to perform ongoing monitoring on all clients and related entities',
	[UserCapabilities.CAN_MANAGE_USERS]:
		'Invite new users to TrustForm and manage their permissions and data access',
	[UserCapabilities.CAN_EXPORT_ACCOUNT_DATA]:
		'Export client account data for reporting, compliance, or further analysis',
};

interface Props {
	user: BackendTypes.UserInfo;
}

export const UserCard: FC<Props> = ({ user }) => {
	const currentUser = useCurrentUser();
	const isEditingThemself = currentUser.userId !== user.userId;
	const allCapabilities = getCapabilities();

	const [firstName, setFirstName] = useState(user.firstName || '');
	const [lastName, setLastName] = useState(user.lastName || '');
	const [capabilities, setCapabilities] = useState<Record<BackendTypes.RoleCapabilityValue, boolean>>(
		allCapabilities.reduce((acc, c) => {
			return {
				...acc,
				[c.value]: user.listRoleCapabilities.includes(c.value),
			};
		}, {} as Record<BackendTypes.RoleCapabilityValue, boolean>)
	);

	const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
	const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);

	const handleToggleCapability = (capability: BackendTypes.RoleCapabilityValue) => {
		setCapabilities((prevCapabilities) => ({
			...prevCapabilities,
			[capability]: !prevCapabilities[capability],
		}));
	};

	const updateUserMutation = useUpdateUserMutation();
	const saveChanges = async () => {
		if (Object.values(capabilities).filter(Boolean).length === 0) {
			return TFNotifier.error('Please select at least one capability');
		}
		await updateUserMutation.mutateAsync(
			{
				id: user.userId,
				data: {
					lastName,
					firstName,
					userParams: {
						listRoleCapabilities: Object.entries(capabilities)
							.filter(([, isEnabled]) => isEnabled)
							.map(([capability]) => capability as BackendTypes.RoleCapabilityValue),
					},
				},
			},
			{
				onSuccess: () => {
					TFNotifier.success('User profile updated');
				},
			}
		);
	};

	return (
		<TFCard>
			<Stack spacing="sm" p="1rem">
				<TextInput
					label="First name"
					name="firstName"
					placeholder="John"
					value={firstName}
					onChange={handleFirstNameChange}
				/>
				<TextInput
					label="Last name"
					name="lastName"
					placeholder="Doe"
					value={lastName}
					onChange={handleLastNameChange}
				/>
				<TFField label="Email" value={user.username} />
				<TFSection title="Capabilities">
					<Stack spacing="md">
						{allCapabilities.map((capability) => (
							<Box key={capability.value} maw="360px">
								<Checkbox
									label={capability.label}
									description={capabilitiesDescription[capability.value]}
									checked={capabilities[capability.value]}
									disabled={!isEditingThemself && capability.value === UserCapabilities.CAN_MANAGE_USERS}
									onChange={() => handleToggleCapability(capability.value)}
								/>
							</Box>
						))}
					</Stack>
				</TFSection>
			</Stack>
			<Box
				sx={({ colors }) => ({
					display: 'flex',
					justifyContent: 'end',
					padding: '1rem',
					marginTop: '.5rem',
					borderTopStyle: 'solid',
					borderTopWidth: '1px',
					borderTopColor: colors.gray[2],
				})}
			>
				<Button size="md" loading={updateUserMutation.isPending} onClick={saveChanges}>
					Save changes
				</Button>
			</Box>
		</TFCard>
	);
};
