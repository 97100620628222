import React, { useState } from 'react';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	createModal,
	Icon,
	Menu,
	Textarea,
	TFNotifier,
	TFText,
	useDisclosure,
} from '@tf/ui';
import type { FormSegment } from '@tf/utils';

import { useReviewDecisionMutation } from '@/core/api/reviewDecision';
import { useAccountParams } from '@/core/hooks';
import { ModalFooter } from '@/components/shared';

interface Props {
	reviewStatus: string;
	segments: FormSegment[];
	graphId: number;
}

export const ReviewDecisionButton: React.FC<Props> = ({ segments, reviewStatus, graphId }) => {
	const { accountId, connectionId, formKind } = useAccountParams();
	const { mutateAsync, isPending } = useReviewDecisionMutation();
	const rejectModal = useDisclosure();

	const makeDecision = async (status: BackendTypes.ReviewStatus, comment?: string) => {
		await mutateAsync(
			{
				accountId,
				connectionId,
				graphId,
				formKind,
				segmentIdentities: segments,
				status,
				comment,
			},
			{
				onSuccess: () => {
					TFNotifier.success('Form review status updated');
					rejectModal.close();
				},
				onError: () => {
					TFNotifier.error('Something went wrong. Please try again later');
				},
			}
		);
	};

	const handleReject = () => rejectModal.open();

	const handleApprove = async () => makeDecision('ACCEPTED');

	return (
		<>
			<Menu arrowOffset={0} offset={0} position="bottom-end" arrowPosition="center">
				<Menu.Target>
					<Button loading={isPending} loaderProps={{ size: 14 }}>
						Make Decision
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item
						p=".375rem 1rem"
						color="green"
						icon={<Icon.IconCheck size={18} />}
						disabled={reviewStatus === 'ACCEPTED'}
						onClick={handleApprove}
					>
						<TFText weight={500}>Approve</TFText>
					</Menu.Item>
					<Menu.Divider />
					<Menu.Item
						p=".375rem 1rem"
						color="red"
						icon={<Icon.IconX size={18} />}
						onClick={handleReject}
						disabled={reviewStatus === 'REJECTED'}
					>
						<TFText weight={500}>Reject</TFText>
					</Menu.Item>
				</Menu.Dropdown>
			</Menu>
			<RejectModal
				title="Rejection confirmation"
				onClose={rejectModal.close}
				opened={rejectModal.isOpen}
				contentProps={{
					onSubmit: (comment: string) => makeDecision('REJECTED', comment),
					onClose: rejectModal.close,
				}}
			/>
		</>
	);
};

interface RejectModalProps {
	onSubmit: (comment: string) => Promise<void>;
	onClose: () => void;
}

export const RejectModal = createModal<RejectModalProps>(({ onSubmit, onClose }) => {
	const [comment, setComment] = useState<string>('');

	return (
		<>
			<Box m="1rem">
				<TFText>
					In addition to the decision, you can give reasons for refusal or information to clarify
					customer data.
				</TFText>
				<Textarea
					label="Comment"
					placeholder="Describe the reason for rejection (optional)"
					maxRows={6}
					mt="1rem"
					value={comment}
					onChange={(e) => setComment(e.currentTarget.value)}
				/>
			</Box>
			<ModalFooter submitText="Reject" onCancel={onClose} onSubmit={() => onSubmit(comment)} />
		</>
	);
});
