import React, { useState } from 'react';

import { useSelector } from '@datagrid/state';

import { type BackendTypes } from '@tf/api';
import {
	Box,
	createStyles,
	Highlight,
	Icon,
	Loader,
	SegmentedControl,
	Select,
	TextInput,
	TFNotifier,
	TFText,
	UnstyledButton,
	useDebounce,
} from '@tf/ui';
import type { ConnectionErrorResponse, SubmitHandler } from '@tf/utils';
import { connectionErrorsMap, S } from '@tf/utils';

import { useConnectExistingEntityMutation, useConnectNewEntityMutation } from '@/core/api/connections';
import { useEntityLookupQuery } from '@/core/api/entities';
import { useProcessingScripts } from '@/core/hooks';
import { appStore } from '@/core/stores';
import { getSelfSegment } from '@/core/utils';
import { useNavigateToEntity } from '@/core/utils/routes';
import { AppFormBuilder, ModalFooter } from '@/components/shared';

export interface ConnectionFormProps {
	parentEntity: {
		kind: BackendTypes.EntityKind;
		graphId: BackendTypes.GraphId;
	};
	connectionKind: BackendTypes.EntityConnectionKind;
	review?: {
		graphLinkId: BackendTypes.GraphId;
		connectionKind: BackendTypes.EntityConnectionKind;
	};
	skipRedirect?: boolean;
}

interface Props extends ConnectionFormProps {
	onClose: () => void;
	onSuccess: () => void;
}

type ConnectionType = 'new' | 'existing';

const useStyles = createStyles(({ colors, radius }) => ({
	suggestion: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		padding: '.5rem',
		borderStyle: 'solid',
		transition: 'background-color .2s ease-in-out',
		borderWidth: 1,
		borderColor: colors.light[3],
		borderRadius: radius.sm,
		marginTop: '.5rem',
		'&[data-selected="true"]': {
			color: colors.brand[6],
			borderColor: colors.brand[6],
			backgroundColor: colors.brand[0],
		},
	},
}));
export const ConnectionForm: React.FC<Props> = (props) => {
	const { classes } = useStyles();
	const { navigateToEntity } = useNavigateToEntity();

	// TODO: This is a temporary solution. We need to find a way to get the list of available entities
	const defs = useSelector(() => appStore.defs.get());
	const entityDef = defs.entities[props.parentEntity.kind];
	const connectionDef = entityDef.possibleConnections.find((c) => c.kind === props.connectionKind);
	const availableEntities = connectionDef?.fromEntities.map((e) => e.kind) || [];

	const scripts = useProcessingScripts(availableEntities.map((item) => getSelfSegment(item)));

	const [selectedKind, setSelectedKind] = useState<string>(availableEntities[0]);
	const [connectionType, setConnectionType] = useState<ConnectionType>('new');
	const [searchValue, setSearchValue] = useState('');
	const [selectedEntityId, setSelectedEntityId] = useState<number | null>(null);

	const onConnectionSuccess = (
		connectedLinkId: BackendTypes.GraphId,
		connectedEntityId: BackendTypes.GraphId
	) => {
		props.onSuccess();
		TFNotifier.success('Connection created successfully');

		if (props.skipRedirect) {
			return;
		}

		if (!props.review || props.review.connectionKind === props.connectionKind) {
			// New review created, so use its id in link
			navigateToEntity(connectedLinkId, props.connectionKind, connectedEntityId);
		} else {
			navigateToEntity(props.review.graphLinkId, props.review.connectionKind, connectedEntityId);
		}
	};

	const connectNewEntityMutation = useConnectNewEntityMutation();
	const connectNewEntity: SubmitHandler = async ({ values }) => {
		try {
			const selfSegment = getSelfSegment(selectedKind);
			const selfSegmentData = values[selfSegment];

			const result = await connectNewEntityMutation.mutateAsync({
				parentEntityId: props.parentEntity.graphId,
				connectionKind: props.connectionKind,
				entityKind: selectedKind,
				selfSegmentData,
			});

			onConnectionSuccess(result.connectedLinkId, result.connectedEntityId);
		} catch (err) {
			const message = err instanceof Error ? err.message : 'Unknown error';
			TFNotifier.error(message);
		}
	};

	const connectExistingEntityMutation = useConnectExistingEntityMutation();
	const connectExistingEntity = async () => {
		try {
			if (!selectedEntityId) {
				throw new Error('Entity not selected');
			}

			const result = await connectExistingEntityMutation.mutateAsync({
				parentEntityId: props.parentEntity.graphId,
				connectionKind: props.connectionKind,
				entityId: selectedEntityId,
			});

			onConnectionSuccess(result.connectedLinkId, selectedEntityId);
		} catch (err) {
			if (err instanceof Response) {
				try {
					const errorResponse: ConnectionErrorResponse = await err.json();
					if (errorResponse.message === 'failed on db rules check') {
						TFNotifier.error('Failed to add connection');
						return;
					}
					for (const [key, value] of Object.entries(errorResponse)) {
						if (value.length) {
							TFNotifier.error(connectionErrorsMap[key]);
							return;
						}
					}
				} catch (e) {
					console.log('Failed to parse error', e);
				}
			} else {
				const message = err instanceof Error ? err.message : 'Unknown error';
				TFNotifier.error(message);
			}
		}
	};

	// * Refetch the list of available entities when the search value changes
	const query = useDebounce(searchValue, 150);
	const entityLookupQuery = useEntityLookupQuery(selectedKind, query);
	const suggestedEntities = entityLookupQuery.data?.slice(0, 10) ?? [];

	return (
		<>
			{props.connectionKind !== 'NOMINATED_ACCOUNT' && (
				<SegmentedControl
					value={connectionType}
					onChange={(value: ConnectionType) => setConnectionType(value)}
					fullWidth
					data={[
						{ label: 'New entity', value: 'new' },
						{ label: 'Existing entity', value: 'existing' },
					]}
					m=".75rem .75rem .5rem"
				/>
			)}
			{availableEntities.length > 1 && (
				<Select
					label="Entity kind"
					value={selectedKind}
					onChange={(value) => {
						if (value) setSelectedKind(value);
					}}
					placeholder="Select a kind of entity"
					data={availableEntities.map((kind) => ({ value: kind, label: S.prettify(kind) }))}
					m=".25rem .75rem 0"
				/>
			)}
			{connectionType === 'new' && (
				<AppFormBuilder
					segments={[
						{
							segmentKind: getSelfSegment(selectedKind),
							graphId: 0,
							accessMode: 'MODIFY',
						},
					]}
					shouldValidate
					processingScripts={scripts}
					styles={{ content: { padding: '.5rem .75rem .25rem' } }}
					submitButtonRenderer={() => (
						<ModalFooter
							submitText="Create"
							isLoading={connectNewEntityMutation.isPending}
							isDisabled={connectNewEntityMutation.isPending}
							onCancel={props.onClose}
						/>
					)}
					onSubmit={connectNewEntity}
				/>
			)}
			{connectionType === 'existing' && (
				<>
					<Box m=".5rem .75rem .75rem">
						<TextInput
							label="Name"
							placeholder={`Search for ${S.prettify(selectedKind)}...`}
							value={searchValue}
							onChange={(e) => setSearchValue(e.target.value)}
							rightSection={entityLookupQuery.isFetching && <Loader variant="oval" size="xs" />}
						/>

						{suggestedEntities.map(({ graphNodeId, name }) => {
							const isSelected = graphNodeId === selectedEntityId;
							return (
								<UnstyledButton
									key={graphNodeId}
									className={classes.suggestion}
									onClick={() => {
										if (isSelected) setSelectedEntityId(null);
										else setSelectedEntityId(graphNodeId);
									}}
									data-selected={isSelected}
								>
									{isSelected ? (
										<>
											<TFText h={22} lineClamp={1}>
												{name}
											</TFText>
											<Icon.IconCircleCheck size={18} />
										</>
									) : (
										<Highlight highlight={searchValue} lineClamp={1}>
											{name}
										</Highlight>
									)}
								</UnstyledButton>
							);
						})}
					</Box>

					<ModalFooter
						submitText="Connect"
						isLoading={connectExistingEntityMutation.isPending}
						isDisabled={selectedEntityId === null}
						onSubmit={connectExistingEntity}
						onCancel={props.onClose}
					/>
				</>
			)}
		</>
	);
};
