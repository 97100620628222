import { SignedIn } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';

import { AppShell, Box } from '@tf/ui';

import { Affix } from './Affix';
import { AppHeader } from './AppHeader';

export const AppLayout = () => {
	return (
		<SignedIn>
			<AppShell header={<AppHeader />}>
				<Box p="0 1rem 3rem">
					<Outlet />
				</Box>
				<Affix />
			</AppShell>
		</SignedIn>
	);
};
