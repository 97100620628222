import React from 'react';

import { useSelector } from '@datagrid/state';
import { Link } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	createStyles,
	Flex,
	Icon,
	TFDataTable,
	TFText,
	Tooltip,
	UnstyledButton,
	useDebouncedValue,
	useLocalStorage,
} from '@tf/ui';
import { D, fmt, S } from '@tf/utils';

import { useOverviewAccounts, useOverviewData } from '@/core/api/overview';
import { appStore } from '@/core/stores';
import { getDisplayName } from '@/core/utils';
import { ReviewState, TFBadge, TFBadgeColors } from '@/components/shared';

import { AssignedManagersWidget } from '../../shared/AssignedManagers';

import { AccountStatus } from './AccountStatus';
import { PriorityButton } from './PriorityButton';

const riskLevelColors: Record<BackendTypes.OverviewAccountRiskLevel, TFBadgeColors> = {
	VERY_HIGH: TFBadgeColors.RED,
	HIGH: TFBadgeColors.ORANGE,
	MEDIUM: TFBadgeColors.YELLOW,
	LOW: TFBadgeColors.GREEN,
};

const useStyles = createStyles(({ colors }) => ({
	accountName: {
		fontSize: 13,
		height: 32,
		display: 'flex',
		alignItems: 'center',
		color: colors.brand[6],
		'&:hover': {
			color: colors.brand[6],
			textDecoration: 'underline',
		},
	},
	accountNameText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 300,
	},
}));

const filtersNamesMap: Record<string, string> = {
	kind: 'accountType',
	'values.accountStatus': 'accountStatus',
	verification: 'verificationStatus',
	importance: 'priority',
	assignedTo: 'assignedTo',
};

const sortingNamesMap: Record<string, string> = {
	name: 'ACCOUNT_NAME',
	kind: 'ACCOUNT_TYPE',
	'values.accountStatus': 'ACCOUNT_STATUS',
	verification: 'VERIFICATION_STATUS',
	importance: 'PRIORITY',
	'values.nextKycReviewDate': 'KYC_VIEW',
	statusChangedAt: 'ACCOUNT_STATUS_CHANGED_AT',
};

export const AccountTable = () => {
	const { classes } = useStyles();
	const accountKinds = useSelector(() => appStore.defs.options['AccountKind'].get());

	const [pagination, setPagination] = useLocalStorage<{ pageSize: number; pageIndex: number }>({
		defaultValue: { pageSize: 10, pageIndex: 0 },
		key: 'accounts-table-pagination',
		getInitialValueInEffect: false,
	});

	const [searchValue, setSearchValue] = useLocalStorage<string>({
		defaultValue: '',
		key: 'accounts-table-search',
		getInitialValueInEffect: false,
	});
	const [debouncedSearch] = useDebouncedValue(searchValue, 500);

	const [filtersState, setFiltersState] = useLocalStorage<
		{
			id: string;
			value: unknown;
		}[]
	>({
		defaultValue: [],
		key: 'account-table-filters',
		getInitialValueInEffect: false,
	});

	const [sortingState, setSortingState] = useLocalStorage<
		{
			desc: boolean;
			id: string;
		}[]
	>({
		defaultValue: [{ desc: true, id: 'ACCOUNT_NAME' }],
		key: `account-table-sorting`,
		getInitialValueInEffect: false,
	});

	const sortingParams =
		sortingState.length > 0
			? {
					sortBy: sortingNamesMap[sortingState[0].id] as BackendTypes.OverviewSortBy | undefined,
					sortOrder:
						sortingState[0].id === 'statusChangedAt'
							? sortingState[0].desc
								? 'ASC'
								: 'DESC'
							: sortingState[0].desc
							? 'DESC'
							: ('ASC' as BackendTypes.OverviewSortOrder),
			  }
			: undefined;

	const filteringParams = filtersState.reduce<Record<string, any>>((acc, curr) => {
		acc[filtersNamesMap[curr.id]] = curr.value;
		return acc;
	}, {});

	const { data: accounts, isPending } = useOverviewAccounts(
		pagination.pageIndex + 1,
		pagination.pageSize,
		{
			...filteringParams,
			...sortingParams,
			query: debouncedSearch,
		}
	);

	const { data } = useOverviewData();
	const listUsers = data?.listUsers;
	const hasArchivedStatusMessage = Boolean(
		!filtersState.find((f) => f.value === 'ARCHIVED') && !!debouncedSearch
	);

	return (
		<>
			<TFDataTable<BackendTypes.OverviewAccount>
				noDataText={
					hasArchivedStatusMessage ? (
						<HiddenAccountsNotice
							textSize="16px"
							onClick={() => setFiltersState([{ id: 'values.accountStatus', value: 'ARCHIVED' }])}
						/>
					) : undefined
				}
				name="account-list"
				isLoading={isPending}
				serverSorting={{
					onSortingChange: setSortingState,
					state: sortingState,
				}}
				serverFiltering={{
					onFiltersChange: setFiltersState,
					state: filtersState,
				}}
				serverPagination={
					accounts
						? {
								...pagination,
								rowCount: accounts.count,
								onPaginationChange: setPagination,
						  }
						: undefined
				}
				serverSearch={{
					state: searchValue,
					onSearchChange: setSearchValue,
				}}
				data={accounts?.items ?? []}
				defs={[
					{
						header: 'Account name',
						accessorKey: 'name',
						size: 200,
						Cell: ({ cell }) => {
							const { nodeId, name } = cell.row.original;
							return (
								<UnstyledButton
									component={Link}
									to={`/accounts/${nodeId}`}
									className={classes.accountName}
								>
									<Tooltip label={name || 'N/A'} position="top">
										<TFText className={classes.accountNameText}>{name || 'N/A'}</TFText>
									</Tooltip>
								</UnstyledButton>
							);
						},
					},
					{
						header: 'Account type',
						accessorKey: 'kind',
						Cell: ({ cell }) => {
							const nextKind = accountKinds?.find((a: { value: string }) => a.value === cell.getValue());
							return <TFText lineClamp={1}>{nextKind?.label || 'Not defined'}</TFText>;
						},
					},
					{
						header: 'Account status',
						id: 'values.accountStatus',
						accessorKey: 'values.accountStatus',
						sortUndefined: 1, // lower priority
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							return <AccountStatus accountStatus={row.original.values?.accountStatus} />;
						},
					},
					{
						header: 'Time in status',
						id: 'statusChangedAt',
						accessorKey: 'statusChangedAt',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const changedAt = row.original.statusChangedAt;
							return (
								<Tooltip
									label={`Since ${fmt.toDate(changedAt, { preset: 'full_date_with_time' })}`}
									position="top"
								>
									<div>{D.calculateTillNow(changedAt)}</div>
								</Tooltip>
							);
						},
					},
					{
						header: 'Verification status',
						accessorKey: 'verification',
						Cell: ({ cell }) => <ReviewState account={cell.row.original} />,
					},
					{
						header: 'Assigned managers',
						accessorKey: 'assignedManagers',
						enableSorting: false,
						Cell: (data: { row: { original: { nodeId: number; assignedManagers: string[] } } }) => (
							<AssignedManagersWidget
								accountId={data.row.original.nodeId}
								selectedIds={data.row.original.assignedManagers}
								managers={listUsers ?? []}
							/>
						),
					},
					{
						header: 'Priority',
						accessorKey: 'importance',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => (
							<PriorityButton accountId={row.original.nodeId} value={row.original.importance} />
						),
					},
					{
						header: 'Risk profile',
						accessorKey: 'values.riskLevel',
						enableSorting: false,
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const riskLevel = row.original.values.riskLevel;
							return (
								<TFBadge
									withBackground={false}
									text={riskLevel ? S.prettify(riskLevel) : 'Not set'}
									color={riskLevel ? riskLevelColors[riskLevel] : TFBadgeColors.DIMMED}
								/>
							);
						},
					},
					{
						header: 'Next KYC review',
						accessorKey: 'values.nextKycReviewDate',
						Cell: ({ row }: { row: { original: BackendTypes.OverviewAccount } }) => {
							const date: string | undefined = row.original.values.nextKycReviewDate;
							const status = row.original.values.accountStatus;
							if (!date || status === 'REJECTED' || status === 'CLOSED') {
								return <TFText color="dimmed">Not defined</TFText>;
							}
							const daysOverdue = D.getOverdueDaysCount(date);
							return (
								<TFText color={daysOverdue ? 'red' : 'initial'}>
									{daysOverdue > 0
										? `Overdue ${daysOverdue} ${daysOverdue > 1 ? 'days' : 'day'}`
										: fmt.toDate(date, { preset: 'full_date' })}
								</TFText>
							);
						},
					},
				]}
				availableFilters={[
					{
						name: 'kind',
						label: 'Account type',
						type: 'select',
						value: accountKinds,
					},
					{
						name: 'values.accountStatus',
						label: 'Account status',
						type: 'select',
						value: [
							{ value: 'PROSPECT', label: 'Prospect' },
							{ value: 'ACTIVE', label: 'Active' },
							{ value: 'SUSPENDED', label: 'Suspended' },
							{ value: 'REJECTED', label: 'Rejected' },
							{ value: 'CLOSED', label: 'Closed' },
							{ value: 'ARCHIVED', label: 'Archived' },
						],
					},
					{
						name: 'verification',
						type: 'select',
						label: 'Verification status',
						value: [
							{ value: 'DRAFT', label: 'Draft' },
							{ value: 'READY_TO_REVIEW', label: 'Ready to review' },
							{ value: 'UNDER_REVIEW', label: 'Pending review' },
							{ value: 'VERIFIED', label: 'Verified' },
							{ value: 'UPDATE_REQUIRED', label: 'Update required' },
						],
					},
					{
						name: 'importance',
						type: 'select',
						label: 'Priority',
						value: [
							{ value: 'HIGH', label: 'High' },
							{ value: 'MEDIUM', label: 'Medium' },
							{ value: 'LOW', label: 'Low' },
						],
					},
					{
						name: 'assignedTo',
						type: 'select',
						label: 'Assigned manager',
						value: [
							{ value: '__unassigned__', label: 'Not assigned' },
							...(listUsers?.map((user) => ({ value: user.userId, label: getDisplayName(user) })) || []),
						],
					},
				]}
			/>
			<Box mt="12px">
				{hasArchivedStatusMessage && Boolean(accounts?.items.length) && (
					<HiddenAccountsNotice
						textSize="13px"
						withIcon
						onClick={() => setFiltersState([{ id: 'values.accountStatus', value: 'ARCHIVED' }])}
					/>
				)}
			</Box>
		</>
	);
};

interface Props {
	textSize: string;
	onClick: () => void;
	withIcon?: boolean;
}

export const HiddenAccountsNotice: React.FC<Props> = ({ textSize, onClick, withIcon = false }) => {
	const { classes } = useStyles();

	return (
		<Flex justify="center" align="center">
			{withIcon && <Icon.IconEyeOff size="16px" />}
			<TFText ml={withIcon ? '8px' : '0'} size={textSize} mr="4px">
				Some accounts may be hidden in
			</TFText>
			<UnstyledButton onClick={onClick} className={classes.accountName}>
				<TFText size={textSize}>Archived status</TFText>
			</UnstyledButton>
		</Flex>
	);
};
