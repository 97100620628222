import React from 'react';

import type { BackendTypes } from '@tf/api';
import { Button, createStyles, Flex, TFDataTable, TFLoadingOverlay, TFText, Tooltip } from '@tf/ui';

import { useRevokeUserInvitationMutation, useUsersInvitations } from '@/core/api/users';
import { capabilities, capabilitiesIcons, getDisplayName } from '@/core/utils';

const useStyles = createStyles(({ colors }) => ({
	icon: {
		color: colors.gray[6],
	},
}));

export const UserInvitationsTable = () => {
	const { classes } = useStyles();
	const { isPending, isError, data: usersInvitations } = useUsersInvitations();
	const revokeUserInvitationMutation = useRevokeUserInvitationMutation();

	if (isPending) {
		return <TFLoadingOverlay h={300} label="Invitations loading..." />;
	}

	if (isError) {
		return <div>Error, please try again</div>;
	}

	return (
		<TFDataTable<BackendTypes.UserInvitation>
			name="inviations-table"
			data={usersInvitations}
			defs={[
				{
					header: 'Name',
					accessorKey: 'name',
					accessorFn: (user) =>
						getDisplayName({ firstName: user.firstName, lastName: user.lastName, username: 'N/A' }),
					size: 150,
					sortingFn: (rowA, rowB) => {
						const rowAName = getDisplayName({
							firstName: rowA.original.firstName,
							lastName: rowA.original.lastName,
							username: 'N/A',
						});
						const rowBName = getDisplayName({
							firstName: rowB.original.firstName,
							lastName: rowB.original.lastName,
							username: 'N/A',
						});
						return rowAName.localeCompare(rowBName);
					},
					Cell: ({ row }: { row: { original: BackendTypes.UserInvitation } }) => {
						return (
							<TFText py=".25rem" lineClamp={1}>
								{getDisplayName({
									firstName: row.original.firstName,
									lastName: row.original.lastName,
									username: 'N/A',
								})}
							</TFText>
						);
					},
				},
				{
					header: 'Email',
					accessorKey: 'email',
					size: 100,
					Cell: ({ row }: { row: { original: BackendTypes.UserInvitation } }) => {
						const { email } = row.original;
						return <TFText lineClamp={1}>{email}</TFText>;
					},
				},
				{
					header: 'Capabilities',
					accessorKey: 'capabilities',
					size: 100,
					Cell: ({ row }: { row: { original: BackendTypes.UserInvitation } }) => {
						const { userParams } = row.original;
						return (
							<Flex>
								{userParams.listRoleCapabilities.map((cap) => (
									<Tooltip className={classes.icon} key={cap} label={capabilities[cap]}>
										{capabilitiesIcons[cap]}
									</Tooltip>
								))}
							</Flex>
						);
					},
				},
				{
					header: 'Revoke invitation',
					accessorKey: 'revoke',
					size: 100,
					enableSorting: false,
					enableResizing: false,
					Cell: ({ row }: { row: { original: BackendTypes.UserInvitation } }) => {
						const { email } = row.original;
						return (
							<Button
								disabled={
									revokeUserInvitationMutation.isPending &&
									revokeUserInvitationMutation.variables?.email === email
								}
								onClick={async () => {
									await revokeUserInvitationMutation.mutateAsync({ email });
								}}
								size={'xs'}
							>
								Revoke
							</Button>
						);
					},
				},
			]}
		/>
	);
};
