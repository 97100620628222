import { useQueryClient } from '@tanstack/react-query';
import type { FC } from 'react';

import { BackendClient } from '@tf/api';
import { Button, Group, Icon, Modal, TFNotifier, TFText, Tooltip, useDisclosure } from '@tf/ui';

import { useOverviewAccount } from '@/core/api/overview';
import { useSelectedAccount } from '@/core/hooks';
import type { ExtendedReviewContainer } from '@/core/types';
import { UserCapabilities } from '@/core/types';
import { useCurrentUserCapabilityCheck } from '@/core/utils';
import { ConfirmationModal } from '@/components/shared';
import { AssignedManagersWidget } from '@/components/shared/AssignedManagers';

interface Props {
	review: ExtendedReviewContainer;
}

export const ReviewTransitionButton: FC<Props> = ({ review }) => {
	const confirmationModal = useDisclosure();

	// * Check if user has permission to do transition
	if (!useCurrentUserCapabilityCheck(UserCapabilities.CAN_PREPARE_ACCOUNTS_DATA)) {
		return null;
	}

	if (review.state === 'READY_TO_REVIEW') {
		return (
			<>
				<Tooltip label="Send to compliance review" position="right">
					<Button
						size="xs"
						variant="gradient"
						gradient={{ from: 'brand', to: 'blue' }}
						onClick={confirmationModal.open}
						mr=".25rem"
						leftIcon={<Icon.IconSend size={14} />}
					>
						Send
					</Button>
				</Tooltip>
				<ReviewTransitionModal
					review={review}
					isOpen={confirmationModal.isOpen}
					onClose={confirmationModal.close}
				/>
			</>
		);
	}

	return null;
};

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	review: ExtendedReviewContainer;
}

export const ReviewTransitionModal: React.FC<ModalProps> = ({ isOpen, onClose, review }) => {
	const { meta } = useSelectedAccount();
	const accountId = meta.graphNodeId;
	const { data } = useOverviewAccount(accountId);
	const listUsers = data?.listUsers;

	const assignedManagers = data?.account.assignedManagers ?? [];

	const api = BackendClient.getInstance();
	const queryClient = useQueryClient();

	const setReadiness = async (isReady: boolean) => {
		try {
			await api.reviewTransition(review.graphLinkId, {
				isReady,
				revisionCounter: review.revisionCounter,
			});
			await queryClient.refetchQueries({ queryKey: ['accounts', meta.graphNodeId] });
			await queryClient.refetchQueries({ queryKey: ['overviewAccount', meta.graphNodeId] });
			onClose();
			TFNotifier.success('Account sent to review');
		} catch (e) {
			const message = e instanceof Error ? e.message : 'Unknown error';
			TFNotifier.error(message);
		}
	};

	return (
		<Modal opened={isOpen} onClose={onClose} title="Action confirmation">
			<ConfirmationModal
				message="Please, assign the account to a compliance manager or approve current managers before sending it to review."
				confirmText="Send to review"
				isSubmitDisabled={!assignedManagers.length}
				onConfirm={() => setReadiness(true)}
				onCancel={onClose}
			>
				<Group p="0.75rem 0 0 0" mih="44px">
					<TFText>Assigned managers:</TFText>
					<AssignedManagersWidget
						accountId={accountId}
						selectedIds={assignedManagers}
						managers={listUsers ?? []}
						managersFilter={(m) => {
							return m.listRoleCapabilities.some((capability) => {
								return capability === UserCapabilities.CAN_REVIEW_ACCOUNTS_DATA;
							});
						}}
					/>
				</Group>
			</ConfirmationModal>
		</Modal>
	);
};
