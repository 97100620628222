import React from 'react';

import { Box, Button, DatePicker, Popover, useDisclosure } from '@tf/ui';
import { fmt } from '@tf/utils';

import { DateValue } from '@/components/shared/DateValue';

interface Props {
	status: string;
	selectedDate?: Date;
	setSelectedDate: (date: Date | undefined) => void;
}
export const DueDate: React.FC<Props> = ({ status, selectedDate, setSelectedDate }) => {
	const handleDateChange = (newDate: Date) => {
		if (newDate) {
			setSelectedDate(newDate);
		}
	};

	const popover = useDisclosure();

	const formatedDate = selectedDate && fmt.toDate(selectedDate, { preset: 'date_struct' });

	return (
		<Popover withArrow opened={popover.isOpen} onClose={popover.close}>
			<Popover.Target>
				{status === 'DRAFT' ? (
					<DateValue
						style={{ color: '#155EEF' }}
						value={formatedDate}
						onClick={popover.toggle}
						defaultValue="Set date"
					/>
				) : (
					<DateValue value={formatedDate} />
				)}
			</Popover.Target>
			<Popover.Dropdown>
				<DatePicker
					excludeDate={(date) => new Date() > date}
					locale="en"
					date={selectedDate}
					value={selectedDate}
					onDateChange={handleDateChange}
					onChange={handleDateChange}
				/>
				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button
						fullWidth
						variant="light"
						size="sm"
						mr=".75rem"
						onClick={() => {
							setSelectedDate(undefined);
							popover.close();
						}}
					>
						Cancel
					</Button>
					<Button fullWidth size="sm" onClick={popover.close}>
						Select
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
