import React, { useRef, useState } from 'react';

import type { BackendTypes } from '@tf/api';
import {
	Box,
	Button,
	Group,
	Icon,
	Loader,
	ScrollArea,
	Stack,
	TextInput,
	TFText,
	useViewportSize,
} from '@tf/ui';

import { CommentItem } from './CommentItem';

interface CommentsListProps {
	comments: BackendTypes.ReviewFormComment[];
	isSubmitting: boolean;
	sendComment: (comment: string) => Promise<void>;
}

export const CommentsList: React.FC<CommentsListProps> = ({ comments, isSubmitting, sendComment }) => {
	const [comment, setComment] = useState('');
	const viewport = useRef<HTMLDivElement>(null);
	const { height } = useViewportSize();
	const hasComments = comments?.length > 0;

	const onSendComment = async () => {
		try {
			await sendComment(comment);
			setComment('');
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<>
			{hasComments ? (
				<ScrollArea
					h={comments?.length > 5 ? height / 1.5 : 'auto'}
					viewportRef={viewport}
					type="always"
					scrollbarSize={8}
				>
					<Stack spacing={0}>
						{comments?.map((c) => {
							return (
								<CommentItem
									key={`${c.author?.userId}.${c.createdAt}`}
									author={c.author}
									createdAt={c.createdAt}
									body={c.data?.text}
								/>
							);
						})}
					</Stack>
				</ScrollArea>
			) : (
				<Stack
					p="2rem 1rem"
					align="center"
					sx={({ colors }) => ({
						color: colors.dark[2],
					})}
				>
					<Icon.IconMessageCircleX size={48} stroke={1.5} />
					<TFText size="md" color="dimmed">
						No comments for this form
					</TFText>
				</Stack>
			)}

			<Box
				sx={({ colors }) => ({
					display: 'flex',
					alignItems: 'center',
					padding: '.75rem',
					borderTopStyle: 'solid',
					borderTopWidth: 1,
					borderTopColor: colors.light[2],
				})}
			>
				<TextInput
					placeholder="Write a comment..."
					data-autofocus
					disabled={isSubmitting}
					value={comment}
					onChange={(e) => setComment(e.currentTarget.value)}
					onKeyDown={async (e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							await onSendComment();
						}
					}}
					styles={{
						root: { flex: 1 },
						input: { borderRadius: '.25rem 0 0 .25rem' },
					}}
					radius="lg"
				/>
				<Button
					onClick={onSendComment}
					disabled={comment.length === 0 || isSubmitting}
					radius="lg"
					styles={{
						root: {
							borderRadius: '0 .25rem .25rem 0',
						},
					}}
				>
					{isSubmitting ? (
						<Group>
							<Loader size={18} color="gray" />
							Sending...
						</Group>
					) : (
						'Send'
					)}
				</Button>
			</Box>
		</>
	);
};
