import { nanoid } from 'nanoid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import {
	ActionIcon,
	Box,
	Button,
	Divider,
	Flex,
	Group,
	Icon,
	Input,
	MultiSelect,
	Stack,
	TextInput,
	TFCard,
	TFNotifier,
} from '@tf/ui';
import { isValidEmail } from '@tf/utils';

import { useUserInvitationMutation } from '@/core/api/users';
import { getCapabilities } from '@/core/utils';

export const UserInvitationForm = () => {
	const navigate = useNavigate();
	const allCapabilities = getCapabilities();
	const userInvitationMutation = useUserInvitationMutation();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			invites: [
				{
					id: nanoid(),
					email: '',
					firstName: '',
					lastName: '',
					userParams: {
						listRoleCapabilities: [],
					},
				},
			],
		},
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'invites',
	});

	const submit = async ({ invites }: { invites: BackendTypes.UserInvitation[] }) => {
		await userInvitationMutation.mutateAsync(invites, {
			onSuccess: () => {
				TFNotifier.success('Invitations sent');
				navigate('/settings/invitations');
			},
			onError: () => {
				TFNotifier.error('Unable to send invitations');
			},
		});
	};

	return (
		<form onSubmit={handleSubmit(submit)}>
			<TFCard innerPadding=".75rem 1rem">
				<Stack spacing="sm">
					{fields.map((field, idx) => (
						<Group key={field.id} m="auto" align="baseline" noWrap>
							<Stack>
								<Group grow>
									<Controller
										name={`invites.${idx}.firstName`}
										control={control}
										render={({ field, fieldState }) => (
											<Input.Wrapper label="First name">
												<TextInput
													{...field}
													w={250}
													placeholder="Enter user's first name"
													error={Boolean(fieldState.error)}
												/>
											</Input.Wrapper>
										)}
									/>
									<Controller
										name={`invites.${idx}.lastName`}
										control={control}
										render={({ field, fieldState }) => (
											<Input.Wrapper label="Last name">
												<TextInput
													{...field}
													placeholder="Enter user's last name"
													w={250}
													error={Boolean(fieldState.error)}
												/>
											</Input.Wrapper>
										)}
									/>
									<Controller
										name={`invites.${idx}.email`}
										control={control}
										rules={{ required: true, validate: isValidEmail }}
										render={({ field, fieldState }) => (
											<Input.Wrapper label="Email" labelProps={{ required: true }}>
												<TextInput
													{...field}
													placeholder="Enter user's email"
													w={250}
													error={Boolean(fieldState.error)}
												/>
											</Input.Wrapper>
										)}
									/>
								</Group>
								<Group grow>
									<Controller
										name={`invites.${idx}.userParams.listRoleCapabilities`}
										control={control}
										rules={{ required: true }}
										render={({ field, fieldState }) => (
											<Input.Wrapper label="Capabilities" labelProps={{ required: true }}>
												<MultiSelect
													{...field}
													placeholder="Select user's capabilities"
													miw={250}
													data={allCapabilities || []}
													error={Boolean(fieldState.error)}
												/>
											</Input.Wrapper>
										)}
									/>
								</Group>
								{idx !== fields.length - 1 && <Divider my="sm" />}
							</Stack>
							<Box>
								<ActionIcon
									w={30}
									h={30}
									radius="lg"
									p=".3rem"
									disabled={fields.length === 1}
									onClick={() => remove(idx)}
									color="gray"
								>
									<Icon.IconX size={20} />
								</ActionIcon>
							</Box>
						</Group>
					))}
				</Stack>

				<Flex justify="flex-end">
					<Button
						size="xs"
						variant="light"
						mt="1rem"
						disabled={fields.length >= 5}
						onClick={() =>
							append({
								id: nanoid(),
								firstName: '',
								lastName: '',
								email: '',
								userParams: {
									listRoleCapabilities: [],
								},
							})
						}
						leftIcon={<Icon.IconPlus size={16} />}
					>
						Add user
					</Button>
				</Flex>
			</TFCard>

			<TFCard innerPadding=".75rem 1rem" mt=".75rem">
				<Flex justify="flex-end">
					<Button
						size="md"
						loading={userInvitationMutation.isPending}
						leftIcon={<Icon.IconSend size={16} />}
						type="submit"
					>
						Send
					</Button>
				</Flex>
			</TFCard>
		</form>
	);
};
