import { useNavigate, useParams } from 'react-router-dom';

import { Box, createStyles, Tabs } from '@tf/ui';

const useStyles = createStyles(({ colors, breakpoints }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: breakpoints.xl,
		margin: '0 auto',
		color: colors.light[7],
		borderWidth: '0 0 1px 0',
		borderStyle: 'solid',
		borderColor: colors.light[2],
	},
}));

type PathParams = {
	graphId: string;
};

const ROUTES = [
	{
		value: '/',
		label: 'Monitoring',
	},
	{
		value: '/associations',
		label: 'Associations',
	},
] as const;

export const MonitoringCardTabs = () => {
	const { classes } = useStyles();

	const params = useParams<PathParams>();
	const graphId = parseInt(params.graphId as string, 10);
	const navigate = useNavigate();

	const path = `/${location.pathname.split('/').slice(3, 4).join('/')}`;

	return (
		<Box className={classes.root}>
			<Tabs
				value={path}
				styles={({ colors }) => ({
					root: { flexGrow: 1 },
					tab: {
						padding: '.65rem 1rem',
						borderBottomWidth: 1.5,
						borderRadius: 0,
						"&[data-active='true']": {
							fontWeight: 500,
							color: colors.brand[6],
							backgroundColor: colors.brand[0],
						},
					},
					tabsList: { borderBottomWidth: 0 },
				})}
				onTabChange={(value) => navigate(`/monitoring/${graphId}${value}`)}
			>
				<Tabs.List>
					{ROUTES.map(({ value, label }) => (
						<Tabs.Tab key={value} value={value}>
							{label}
						</Tabs.Tab>
					))}
				</Tabs.List>
			</Tabs>
		</Box>
	);
};
