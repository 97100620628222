import { Box, Center, Icon, TFText } from '@tf/ui';
import { S } from '@tf/utils';

interface Props {
	value: string;
}

export const PriorityView: React.FC<Props> = ({ value }) => {
	return (
		<Box
			sx={({ colors }) => ({
				display: 'flex',
				alignItems: 'center',
				width: '4.5rem',
				height: '1.8rem',
				...(value === 'LOW' && { color: colors.gray[5] }),
				...(value === 'MEDIUM' && { color: colors.orange[5] }),
				...(value === 'HIGH' && { color: colors.red[5] }),
			})}
		>
			<Center opacity={0.7} mr=".5rem">
				<Icon.IconCircleFilled size={10} />
			</Center>

			<TFText
				color="dimmed"
				sx={() => ({
					flex: 1,
					fontSize: 13,
				})}
			>
				{S.prettify(value)}
			</TFText>
		</Box>
	);
};
