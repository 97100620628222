import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import type { BackendTypes } from '@tf/api';
import { Box, Button, createStyles, SegmentedControl, TFNotifier } from '@tf/ui';
import type { SubmitHandler } from '@tf/utils';

import { useProcessingScripts } from '@/core/hooks';
import { instantCheckQuery } from '@/core/queries';
import { instantCheckStore } from '@/core/stores';
import { AppFormBuilder } from '@/components/shared';

enum AvailableEntities {
	Person = 'ADHOC_SEARCH_PERSON_PARAMS',
	Entity = 'ADHOC_SEARCH_ENTITY_PARAMS',
}

const availableEntities = [
	{ value: AvailableEntities.Person, label: 'Person' },
	{ value: AvailableEntities.Entity, label: 'Entity' },
];

const useStyles = createStyles(() => ({
	searchButtonWrapper: {
		margin: '0.75rem',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
}));

export const InstantCheckSearchForm = () => {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const scripts = useProcessingScripts([AvailableEntities.Person, AvailableEntities.Entity]);

	const [selectedKind, setSelectedKind] = useState(availableEntities[0].value);
	const [isSearching, setIsSearching] = useState(false);

	const handleSubmit: SubmitHandler = async ({ values }: any) => {
		const instantCheckParams: BackendTypes.AdhocSearchParams = {};

		if (values[AvailableEntities.Person]) {
			const person = values[AvailableEntities.Person].person;
			instantCheckParams.person = person;
			instantCheckStore.set((s) => ({ ...s, searchName: `${person.firstName} ${person.lastName}` }));
		}

		if (values[AvailableEntities.Entity]) {
			const entity = values[AvailableEntities.Entity].entity;
			instantCheckParams.entity = entity;
			instantCheckStore.set((s) => ({ ...s, searchName: entity.entityName }));
		}

		try {
			setIsSearching(true);
			const res = await instantCheckQuery(instantCheckParams);
			const hits = (res.rawResponse as any).hits as any[];
			const searchId = String(res.searchId);
			if (hits.length === 0) {
				TFNotifier.info('No results found');
				return;
			}
			instantCheckStore.set((s) => ({ ...s, hits, searchId }));
			navigate('/instant-check/hits');
		} catch (e) {
			TFNotifier.error('Could not perform instant check. Please try again later.');
		} finally {
			setIsSearching(false);
		}
	};

	return (
		<>
			<SegmentedControl
				value={selectedKind}
				onChange={(value: AvailableEntities) => setSelectedKind(value)}
				fullWidth
				data={availableEntities.map((kind: any) => ({ value: kind.value, label: kind.label }))}
				m=".75rem .75rem .5rem"
			/>

			<AppFormBuilder
				segments={[
					{
						segmentKind: selectedKind,
						graphId: 0,
						accessMode: 'MODIFY',
					},
				]}
				shouldValidate
				processingScripts={scripts}
				styles={{ content: { padding: '.5rem .75rem .25rem' } }}
				submitButtonRenderer={() => (
					<Box className={classes.searchButtonWrapper}>
						<Button loading={isSearching} loaderProps={{ size: 14 }} type="submit">
							{isSearching ? 'Searching...' : 'Search'}
						</Button>
					</Box>
				)}
				onSubmit={handleSubmit}
			/>
		</>
	);
};
