import { useSelector } from '@datagrid/state';
import { useLocation } from 'react-router-dom';

import { TFPageHeader } from '@tf/shared';
import { Badge, Group } from '@tf/ui';
import { S, T } from '@tf/utils';

import { useClientCasesRequests } from '@/core/api/caseRequests';
import { useAccountParams, useEntityConnectionLabels, useSelectedAccount } from '@/core/hooks';
import { appStore } from '@/core/stores';

import { AccountStructureButton } from './AccountStructureButton';
import { AccountTasksButton } from './AccountTasksButton';

const getAccountLabel = (accountType: string) => {
	switch (accountType) {
		case 'CORPORATE_ACCOUNT':
			return 'corporate';
		case 'INDIVIDUAL_ACCOUNT':
			return 'individual';
		case 'FUND_ACCOUNT':
			return 'fund';
		case 'SPC_FUND_ACCOUNT':
			return 'SPC fund';
		case 'INVESTMENT_FUND_ACCOUNT':
			return 'Investment fund';
		default:
			return accountType.split('_')[0];
	}
};

export const AccountHeader = () => {
	const { meta, reviews } = useSelectedAccount();
	const { connectionKind, entityId, accountId, connectionId, ...other } = useAccountParams();
	const location = useLocation();
	const tenant = useSelector(() => appStore.tenant?.get());
	T.assertNotNullish(tenant, 'no tenant in appStore');
	const accountType = meta.entityKind;
	const isEntity = location.pathname.includes('/entity/');
	const isUpdateRequest = location.pathname.includes('/cases');
	const basePath = `/accounts/${accountId}`;
	const { data: cases } = useClientCasesRequests(accountId);

	const pathSegments = location.pathname.split('/').filter(Boolean);

	const casesIndex = pathSegments.indexOf('cases');
	const caseUniqId =
		casesIndex !== -1 && casesIndex < pathSegments.length - 1 ? pathSegments[casesIndex + 1] : null;

	const uuidToSequentialIdMap = cases?.reduce<Record<string, any>>((map, item) => {
		map[item.uuid] = item.sequential_id;
		return map;
	}, {});

	let title = meta.name;

	const connectionLabels = useEntityConnectionLabels(meta.entityKind);

	// * Breadcrumbs computing
	const breadcrumbs: { title: string; href: string }[] = [
		{ title: 'Accounts', href: '/' },
		{ title, href: `/accounts/${accountId}` },
	];
	if (isEntity) {
		const review = reviews.find((r) => r.graphLinkId == connectionId);
		const entity = review?.listEntities.find((e) => e.graphNodeId == entityId);
		title = entity?.name || 'Unknown';
		breadcrumbs.push(
			{
				href: `${basePath}/${S.slugify(connectionKind)}`,
				title: connectionLabels[connectionKind].plural,
			},
			{
				href: `${basePath}/${S.slugify(connectionKind)}/${connectionId}/entity/${entityId}`,
				title,
			}
		);
	} else if (connectionKind) {
		breadcrumbs.push({
			href: `${basePath}/${S.slugify(connectionKind)}`,
			title: connectionLabels[connectionKind].plural,
		});
	} else if (isUpdateRequest) {
		breadcrumbs.push({
			href: `/accounts/${accountId}/cases`,
			title: 'Cases',
		});
		if (caseUniqId) {
			breadcrumbs.push({
				href: `/accounts/${accountId}/cases/${caseUniqId}`,
				title: `Case ${uuidToSequentialIdMap?.[caseUniqId]}`,
			});
		}
	}

	return (
		<TFPageHeader
			tenant={tenant}
			title={title}
			breadcrumbs={breadcrumbs}
			mb={0}
			badge={
				!isEntity ? (
					<Badge style={{ marginLeft: '0.5rem' }}>{getAccountLabel(accountType)}</Badge>
				) : undefined
			}
			toolbar={
				<Group spacing={12}>
					<AccountTasksButton />
					<AccountStructureButton />
				</Group>
			}
		/>
	);
};
