import { useEffect, useState } from 'react';

import type { FC } from 'react';

import { Box, Button, DatePicker, Popover } from '@tf/ui';
import { fmt } from '@tf/utils';

import { DateValue } from '@/components/shared/DateValue';

interface Props {
	date: string | undefined;
	isLoading: boolean;
	onSave: (newDate: string) => Promise<any>;
}

export const LatestReviewDate: FC<Props> = ({ date, isLoading, onSave }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState(new Date());

	const closePopover = () => setIsOpen(false);
	const togglePopover = () => setIsOpen(!isOpen);

	const onSaveChanges = async () => {
		const nextValue = fmt.toDate(selectedDate, { preset: 'date_struct' });
		await onSave(nextValue);
		closePopover();
	};

	useEffect(() => {
		if (isOpen) {
			setSelectedDate(date ? new Date(date) : new Date());
		}
	}, [date, isOpen]);

	return (
		<Popover withArrow opened={isOpen} onClose={closePopover}>
			<Popover.Target>
				<DateValue value={date} onClick={togglePopover} />
			</Popover.Target>

			<Popover.Dropdown>
				<DatePicker
					locale="en"
					value={selectedDate}
					onChange={(newDate: Date) => {
						if (newDate) {
							setSelectedDate(newDate);
						}
					}}
				/>
				<Box sx={{ display: 'flex', marginTop: '.75rem' }}>
					<Button fullWidth variant="light" size="sm" mr=".75rem" onClick={closePopover}>
						Cancel
					</Button>
					<Button
						loading={isLoading}
						loaderProps={{ size: 14 }}
						fullWidth
						size="sm"
						onClick={onSaveChanges}
					>
						Save
					</Button>
				</Box>
			</Popover.Dropdown>
		</Popover>
	);
};
